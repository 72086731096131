<h2>Comments</h2>

<div class="comments bg-input border-bottom border-input">
  <div *ngIf="!customerSupportComment?.content">No comments</div>
  <div
    class="markdown-compact-list"
    *ngIf="customerSupportComment?.content"
    markdown
  >
    {{ customerSupportComment.content }}
  </div>
</div>
