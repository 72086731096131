import { Component, Input, OnInit } from "@angular/core";
import { CommentInfo } from "../../../generated/tga-api/models/comment-info";

@Component({
  selector: "app-comments",
  templateUrl: "./comments.component.html",
  styleUrls: ["./comments.component.scss"],
})
export class CommentsComponent implements OnInit {
  @Input() customerSupportComment: CommentInfo | null;

  constructor() {}

  ngOnInit(): void {}
}
