import { Injectable, NgZone } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Auth } from "aws-amplify";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private ngZone: NgZone) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    try {
      await Auth.currentAuthenticatedUser();
      return true;
    } catch (e) {
      if (state.url.startsWith("/homes/")) {
        window.localStorage.setItem("requestedUrl", state.url);
      }
      await this.ngZone.run(async () => {
        await this.router.navigate(["login"]);
      });

      return false;
    }
  }
}
