import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { from, Observable } from "rxjs";
import { Auth } from "aws-amplify";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.handleAccessToken(request, next));
  }

  async handleAccessToken(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Promise<HttpEvent<any>> {
    let user = await Auth.currentAuthenticatedUser();
    let accessToken = user
      ?.getSignInUserSession()
      ?.getAccessToken()
      .getJwtToken();
    if (accessToken != null) {
      const authRequest = request.clone({
        setHeaders: { Authorization: "Bearer " + accessToken },
      });

      return next.handle(authRequest).toPromise();
    }

    return next.handle(request).toPromise();
  }
}
