import { Component, OnInit } from "@angular/core";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth, Hub } from "aws-amplify";

@Component({
  selector: "app-auth-button",
  templateUrl: "./auth-button.component.html",
  styleUrls: ["./auth-button.component.scss"],
})
export class AuthButtonComponent implements OnInit {
  user: CognitoUser = null;
  constructor() {}

  async ngOnInit() {
    this.updateCurrentUser();
    Hub.listen("auth", (data) => {
      this.updateCurrentUser();
    });
  }

  async updateCurrentUser() {
    try {
      this.user = await Auth.currentAuthenticatedUser();
    } catch (e) {
      this.user = null;
    }
  }

  async signInWithGoogle() {
    const options = {
      provider: "Google",
    };

    // @ts-ignore
    await Auth.federatedSignIn(options);
  }

  isAuthorized() {
    return this.user !== null;
  }

  async signOut() {
    await Auth.signOut();
  }
}
