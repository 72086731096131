import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Instruction } from "../../../generated/instructions-api/models/instruction";
import { InstructionsListItem } from "./instructions-list-item";

@Component({
  selector: "app-instructions-list",
  templateUrl: "./instructions-list.component.html",
  styleUrls: ["./instructions-list.component.scss"],
})
export class InstructionsListComponent implements OnInit {
  @Input() instructions: Instruction[];

  items: InstructionsListItem[];

  homeId: number;

  constructor(private routeService: ActivatedRoute) {}

  ngOnInit(): void {
    this.homeId = Number(this.routeService.snapshot.data.homeInfo.id);

    this.items = this.instructions.map((instruction) => {
      return {
        id: instruction.id,
        deviceSerialNo: instruction.device.serialNo,
        label: `[${instruction.device.deviceType}] ${instruction.device.serialNo}`,
        icon: "shape-square-plus",
      };
    });
  }
}
