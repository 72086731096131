import { Component, EventEmitter, Input, Output } from "@angular/core";

export interface ToggleChipOption {
  value: any;
  label: string;
  icon: string;
  iconClass: string;
}

@Component({
  selector: "app-toggle-chip",
  templateUrl: "./toggle-chip.component.html",
  styleUrls: ["./toggle-chip.component.scss"],
})
export class ToggleChipComponent {
  @Input() options: ToggleChipOption[];
  @Input() selectedValue: any;
  @Input() disabled: boolean = false;

  @Output() updated = new EventEmitter<any>();

  determineSelectedOption(): ToggleChipOption | null {
    for (let option of this.options) {
      if (option.value === this.selectedValue) {
        return option;
      }
    }

    return null;
  }

  canToggleOption() {
    return this.disabled === false && this.options.length > 0;
  }

  toggleOption() {
    if (this.canToggleOption() === false) {
      return;
    }
    let nextOptionIndex = this.determineSelectedOptionIndex() + 1;
    if (nextOptionIndex >= this.options.length) {
      nextOptionIndex = 0;
    }

    this.selectedValue = this.options[nextOptionIndex].value;

    this.updated.emit(this.selectedValue);
  }

  determineSelectedOptionIndex(): number {
    for (let i = 0; i < this.options.length; i++) {
      if (this.options[i].value === this.selectedValue) {
        return i;
      }
    }

    return -1;
  }
}
