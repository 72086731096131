import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { GenericInstallation } from "../../generated/tga-api/models/generic-installation";
import { InstallationService } from "../../generated/tga-api/services/installation.service";

@Injectable()
export class TgaInstallationsResolver
  implements Resolve<GenericInstallation[]>
{
  constructor(private service: InstallationService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<GenericInstallation[]> {
    return this.service.listInstallations({
      homeId: parseInt(route.paramMap.get("homeId")),
    });
  }
}
