<h2 mat-dialog-title>{{ dialogTitle() }}</h2>
<div>
  <mat-form-field>
    <input
      matInput
      placeholder="Search text"
      [ngModel]="searchText"
      (ngModelChange)="onSearchTextChange($event)"
    />
  </mat-form-field>
</div>
<app-systems-table
  class="flex-grow-1"
  [systems]="systems"
  (selectedSystem)="selectSystem($event)"
></app-systems-table>
