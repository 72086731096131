import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class HvacService {
  private hvacUrl = environment.hvac.url;

  constructor() {}

  getSystemUrlBySystemId(systemId: number): string {
    return `${this.hvacUrl}/genericSystem/show/${systemId}`;
  }
}
