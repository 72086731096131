<mat-chip
  *ngIf="determineSelectedOption() as selectedOption"
  (click)="toggleOption()"
  [ngClass]="{ 'toggle-active': canToggleOption() }"
  class="font-weight-normal"
  [selectable]="false"
  ><app-icon
    [ngClass]="selectedOption.iconClass"
    class="mr-8 ml-n8"
    [icon]="selectedOption.icon"
    [isSelected]="true"
    [size]="24"
  ></app-icon
  >{{ selectedOption.label | titlecase
  }}<app-icon
    *ngIf="canToggleOption()"
    icon="sync-circle"
    class="ml-8 mr-n4 sync"
    size="18"
  ></app-icon
></mat-chip>
