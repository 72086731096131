<h3 class="title">{{ title }}</h3>
<span
  class="text-1 multiline-ellipsis"
  matTooltip="{{ getTooltip() }}"
  matTooltipPosition="left"
>
  {{ system?.modelName }}
</span>
<span class="text-2">ID: {{ system?.modelId }}</span>
<div class="button mt-4">
  <button
    mat-button
    color="warn"
    [disabled]="disabled"
    (click)="removeSystem()"
  >
    remove
  </button>
</div>
<img class="image" src="{{ getSystemImage() }}" alt="System" />
<div class="links">
  <a
    mat-icon-button
    [href]="getSystemUrl()"
    target="_blank"
    aria-label="View the system"
  >
    <app-icon
      icon="open_in_new"
      size="16"
      class="d-flex justify-content-center"
      iconStyle="vertical-align: middle; line-height: 16px;"
    ></app-icon>
  </a>
</div>
