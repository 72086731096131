import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { Instruction } from "../../generated/instructions-api/models/instruction";
import { InstructionsService } from "../../generated/instructions-api/services/instructions.service";

@Injectable()
export class InstructionResolver implements Resolve<Instruction> {
  constructor(private service: InstructionsService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Instruction> {
    return this.service.getDeviceInstruction({
      homeId: parseInt(route.parent.paramMap.get("homeId")),
      serialNo: route.paramMap.get("deviceSerialNo"),
      instructionsId: route.paramMap.get("instructionId"),
    });
  }
}
