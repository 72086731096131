import { Injectable } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class IconService {
  svgIcons = new Set();

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry
  ) {}

  initCustomIcons(): void {
    this.addCustomIcon("content-copy");
    this.addCustomIcon("fire");
    this.addCustomIcon("help-circle");
    this.addCustomIcon("package-up");
    this.addCustomIcon("shape-square-plus");
    this.addCustomIcon("snowflake");
    this.addCustomIcon("sync-circle");
  }

  private addCustomIcon(name: string): void {
    this.matIconRegistry.addSvgIcon(
      name,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `../assets/icons/${name}-24px.svg`
      )
    );

    this.svgIcons.add(name);
  }
}
