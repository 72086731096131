import { ApolloQueryResult } from "@apollo/client/core";
import { QueryRef } from "apollo-angular";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ResolvedSystem } from "../heating-setup/models/resolved-system";
import { SearchSystemsService } from "../../search-systems.service";
import {
  GenericSystem,
  Query,
  SystemRole,
} from "../../../generated/ivar/types";

export interface SystemSearchDialogData {
  systemRole: SystemRole;
}

const waitTypingTimeout = 500;

@Component({
  selector: "app-system-search-dialog",
  templateUrl: "./system-search-dialog.html",
  styleUrls: ["./system-search-dialog.scss"],
})
export class SystemSearchDialog implements OnInit {
  systems: GenericSystem[];
  searchQuery: QueryRef<Query>;
  searchText: string | null;

  constructor(
    private systemSearchService: SearchSystemsService,
    private dialogRef: MatDialogRef<SystemSearchDialog, ResolvedSystem>,
    @Inject(MAT_DIALOG_DATA) public data: SystemSearchDialogData
  ) {}

  ngOnInit(): void {
    this.searchQuery = this.systemSearchService.buildSearchSystemsByRoleQuery([
      this.data.systemRole,
    ]);
  }

  selectSystem(system: GenericSystem): void {
    const imageUrl = system.thumbnail.schematic
      ? system.thumbnail.schematic.url
      : null;

    let resolvedSystem: ResolvedSystem = {
      modelId: Number(system.id),
      role: this.data.systemRole,
      modelName: system.modelName,
      imageUrl,
    };

    this.dialogRef.close(resolvedSystem);
  }

  onSearchTextChange(searchText: string) {
    let searchTextModified = searchText || null;
    this.searchText = searchTextModified;
    let $this = this;
    setTimeout(function () {
      if (searchTextModified === $this.searchText) {
        $this.refetchData();
      }
    }, waitTypingTimeout);
  }

  refetchData() {
    this.searchQuery
      .refetch({
        roles: this.data.systemRole,
        searchText: this.searchText,
      })
      .then((result: ApolloQueryResult<Query>) => {
        this.systems = result.data.searchSystems.systems;
      });
  }

  dialogTitle(): string {
    let roleText: string;
    switch (this.data.systemRole) {
      case SystemRole.Boiler:
        roleText = "Boiler";
        break;
      case SystemRole.ExternalReceiver:
        roleText = "External Receiver";
        break;
      case SystemRole.RoomThermostat:
        roleText = "Room Thermostat";
        break;
      case SystemRole.ExternalControl:
        roleText = "External Control";
        break;
    }

    return `Search ${roleText}`;
  }
}
