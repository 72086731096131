export const environment = {
  production: false,
  genie: {
    installationsUrl: "https://ci-genie.tado.com/api/v1",
    instructionsUrl: "https://ci-genie.tado.com/api/v2",
  },
  tga: {
    apiUrl: "https://ci-genie.tado.com/api/v1",
    installationUrl:
      "https://cidevelop.tado.com/installationSupport/showByInstallation",
    supportUrl: "https://cidevelop.tado.com/support/index",
    visuUrl: "https://cidevelop.tado.com/backendVisualisation/backendvisu",
  },
  ivar: {
    graphqlUrl: "https://ci-ivar.tado.com/graphql",
  },
  hvac: {
    url: "https://ci-hvactool.tado.com",
  },
  amplify: {
    aws_project_region: "eu-west-1",
    aws_cognito_region: "eu-west-1",
    aws_user_pools_id: "eu-west-1_GgrQ7xeE7",
    aws_user_pools_web_client_id: "5tdnvfkjgk33js6lrfj2olbfe7",

    Auth: {
      oauth: {
        domain: "genie-staging.auth.eu-west-1.amazoncognito.com",
        scope: ["email", "profile", "openid"],
        redirectSignIn: "https://ci-genie.tado.com/",
        redirectSignOut: "https://ci-genie.tado.com/",
        responseType: "code",
      },
    },
  },
};
