<app-inverted-stroked-button
  (click)="signInWithGoogle()"
  *ngIf="!isAuthorized()"
>
  Sign in with Google
</app-inverted-stroked-button>

<app-inverted-stroked-button (click)="signOut()" *ngIf="isAuthorized()">
  Sign out
</app-inverted-stroked-button>
