import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ResolvedSystem } from "../../models/resolved-system";

@Component({
  selector: "app-unknown-system-card",
  templateUrl: "./unknown-system-card.component.html",
  styleUrls: [
    "../system-card.component.scss",
    "./unknown-system-card.component.scss",
  ],
})
export class UnknownSystemCardComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() system: ResolvedSystem;
  @Input() title: String;

  @Output() removed = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  removeSystem() {
    this.removed.emit();
  }
}
