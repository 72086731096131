import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class TgaService {
  private installationUrl = environment.tga.installationUrl;
  private supportUrl = environment.tga.supportUrl;
  private visuUrl = environment.tga.visuUrl;

  constructor() {}

  getInstallationUrl(homeId: number, installationId: number): string {
    return `${this.installationUrl}?home.id=${homeId}&managedByGenie=false&installation=${installationId}`;
  }

  getSupportUrl(homeId: number): string {
    return `${this.supportUrl}?home.id=${homeId}`;
  }

  getVisuUrl(homeId: number): string {
    return `${this.visuUrl}?home.id=${homeId}`;
  }
}
