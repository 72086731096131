import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-delete-instruction-dialog",
  templateUrl: "./delete-instruction-dialog.component.html",
  styleUrls: [],
})
export class DeleteInstructionDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DeleteInstructionDialogComponent>
  ) {}

  ngOnInit(): void {}
}
