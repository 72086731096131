<mat-card *ngIf="system === null">
  <app-empty-card
    class="card-grid"
    [title]="title"
    [systemRole]="systemRole"
    [disabled]="disabled"
    (selected)="addSystem($event)"
  ></app-empty-card>
</mat-card>
<mat-card *ngIf="system && !system.modelId">
  <app-unknown-system-card
    class="card-grid"
    [system]="system"
    [title]="title"
    [disabled]="disabled"
    (removed)="removeSystem()"
  ></app-unknown-system-card>
</mat-card>
<mat-card *ngIf="system && system.modelId">
  <app-selected-system-card
    class="card-grid"
    [system]="system"
    [title]="title"
    [disabled]="disabled"
    (removed)="removeSystem()"
  ></app-selected-system-card>
</mat-card>
