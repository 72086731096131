import { ApolloQueryResult } from "@apollo/client/core";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpErrorResponse } from "@angular/common/http";
import { ResponseError } from "../../models/response-error";
import { HomeInfo } from "../../../generated/tga-api/models/home-info";
import { MatDialog } from "@angular/material/dialog";
import { Instruction } from "../../../generated/instructions-api/models/instruction";
import { DeleteInstructionDialogComponent } from "./delete-instruction-dialog/delete-instruction-dialog.component";
import { InstructionsService } from "../../../generated/instructions-api/services/instructions.service";
import { SearchSystemsService } from "../../search-systems.service";
import { ResolvedSystem } from "../heating-setup/models/resolved-system";

import { Query } from "../../../generated/ivar/types";
import { InstructionState } from "../../../generated/instructions-api/models";
import { InstructionService } from "../../../generated/instructions-api/services/instruction.service";

@Component({
  selector: "app-instruction",
  templateUrl: "./instruction.component.html",
  styleUrls: ["./instruction.component.scss"],
})
export class InstructionComponent implements OnInit {
  homeId: number;
  instruction: Instruction;
  selectedSystem: ResolvedSystem;
  selectedBoiler: ResolvedSystem;

  loading = false;
  editModeEnabled = false;

  title: string;

  instructionStateOptions = [
    {
      value: InstructionState.NoYf,
      label: "No Yellow Flags",
      icon: "check_circle",
      iconClass: "text-success",
    },
    {
      value: InstructionState.YfPresent,
      label: "Yellow Flags Present",
      icon: "cancel",
      iconClass: "text-danger",
    },
    {
      value: InstructionState.YfResolved,
      label: "Yellow Flags Resolved",
      icon: "check_circle",
      iconClass: "text-success",
    },
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private instructionService: InstructionService,
    private instructionsService: InstructionsService,
    private searchSystemsService: SearchSystemsService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(() => {
      this.resetLoading();
      const homeInfo = this.activatedRoute.parent.snapshot.data
        .homeInfo as HomeInfo;
      this.homeId = Number(homeInfo.id);

      this.instruction = this.activatedRoute.snapshot.data
        .instruction as Instruction;
      this.selectedSystem = null;
      this.selectedBoiler = null;
      this.loadSelectedSystem();
      this.loadSelectedBoiler();

      this.setTitle(homeInfo);
    });
    this.activatedRoute.queryParams.subscribe((params) => {
      this.editModeEnabled = params["edit"] === "true";
    });
  }

  resetLoading() {
    this.loading = false;
  }

  resolveYellowFlags(state: InstructionState) {
    if (state !== InstructionState.YfResolved) {
      return;
    }
    const beforeState = this.instruction.state;
    this.instruction.state = InstructionState.YfResolved;
    this.instructionsService
      .resolveYellowFlags({
        homeId: this.homeId,
        serialNo: this.instruction.device.serialNo,
        instructionsId: this.instruction.id,
      })
      .subscribe(
        () => {
          this.successApiHandlerWithoutReload();
        },
        (error: HttpErrorResponse) => {
          this.instruction.state = beforeState;
          this.errorApiHandler(error);
        }
      );
  }

  successApiHandlerWithoutReload() {
    this.loading = false;
    this.snackBar.open("Changes have been saved", null, {
      duration: 2000,
    });
  }

  private errorApiHandler(error: HttpErrorResponse) {
    this.loading = false;

    let errors = `HTTP error ${error.status}`;
    try {
      const errorJson = JSON.parse(error.error);
      const errorsArray = (errorJson.errors as ResponseError[])?.map(
        (responseError) => responseError.title
      );
      errors = errorsArray.join(",");
    } catch (e) {}

    this.snackBar.open(`Cannot save: ${errors}`, null, {
      duration: 7000,
    });
  }

  private setTitle(homeInfo: HomeInfo): void {
    this.title = `${homeInfo.contactDetails.name} - ${this.instruction.device.serialNo} [${this.instruction.device.deviceType}]`;
  }

  canResolveYellowFlags(): boolean {
    return this.instruction.state === InstructionState.YfPresent;
  }

  openDeleteConfirmation(): void {
    let dialogRef = this.dialog.open(DeleteInstructionDialogComponent, {
      panelClass: "confirmation-dialog-container",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.deleteInstruction();
      }
    });
  }

  private deleteInstruction(): void {
    this.instructionService
      .deleteInstruction({
        homeId: this.homeId,
        serialNo: this.instruction.device.serialNo,
        instructionsId: this.instruction.id,
      })
      .subscribe(
        () => {
          // Using router.navigate will not re-run the resolvers
          window.location.replace(`/homes/${this.homeId}`);
        },
        (error: HttpErrorResponse) => {
          this.errorApiHandler(error);
        }
      );
  }

  public loadSelectedSystem(): void {
    if (!this.instruction.system) {
      return;
    }
    this.searchSystemsService
      .systemByIdQuery(this.instruction.system.id)
      ?.subscribe((result: ApolloQueryResult<Query>) => {
        this.selectedSystem = {
          imageUrl: result.data.system.thumbnail?.schematic?.url,
          manufacturers: result.data.system.manufacturers,
          modelId: this.instruction.system.id,
          modelName: result.data.system.modelName,
        } as ResolvedSystem;
      });
  }

  public loadSelectedBoiler(): void {
    if (!this.instruction.boiler) {
      return;
    }
    this.searchSystemsService
      .systemByIdQuery(this.instruction.boiler.id)
      ?.subscribe((result: ApolloQueryResult<Query>) => {
        this.selectedBoiler = {
          imageUrl: result.data.system.thumbnail?.schematic?.url,
          manufacturers: result.data.system.manufacturers,
          modelId: this.instruction.boiler.id,
          modelName: result.data.system.modelName,
        } as ResolvedSystem;
      });
  }
}
