import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { GenericInstallation } from "../../../generated/tga-api/models/generic-installation";
import { TgaService } from "../../core/tga.service";
import { InstallationType } from "../../../generated/tga-api/models/installation-type";

@Component({
  selector: "app-installations-list",
  templateUrl: "./installations-list.component.html",
  styleUrls: ["./installations-list.component.scss"],
})
export class InstallationsListComponent implements OnInit {
  @Input() tgaInstallations: GenericInstallation[];

  tgaItems: ListItem[];

  homeId: number;

  constructor(
    private routeService: ActivatedRoute,
    private router: Router,
    private tgaService: TgaService
  ) {}

  ngOnInit(): void {
    this.homeId = Number(this.routeService.snapshot.data.homeInfo.id);
    this.tgaItems = this.tgaInstallations.map((installation) => {
      return {
        id: String(installation.id),
        label: installation.type,
        icon: this.determineIcon(installation),
        isCompleted: false,
      };
    });
  }

  determineIcon(installation: GenericInstallation): string {
    switch (installation.type) {
      case InstallationType.SaleFittingStG1:
        return "package-up";
      case InstallationType.InstallAcG1:
        return "snowflake";
      case InstallationType.ReplaceBridge:
        return "content-copy";
      default:
        return "help-circle";
    }
  }

  navigateToTga(item: ListItem): void {
    const url = this.tgaService.getInstallationUrl(
      this.homeId,
      Number(item.id)
    );
    window.open(url, "_blank");
  }
}

export interface ListItem {
  id: string;
  label: string;
  icon: string;
  isCompleted: boolean;
  lastUpdated?: string;
  lastUpdatedBy?: string;
}
