import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SystemSearchDialog } from "../../../system-search/system-search-dialog";
import { ResolvedSystem } from "../../models/resolved-system";
import { SystemRole } from "../../../../../generated/ivar/types";

@Component({
  selector: "app-empty-card",
  templateUrl: "./empty-card.component.html",
  styleUrls: ["./empty-card.component.scss", "../system-card.component.scss"],
})
export class EmptyCardComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() title: String;
  @Input() systemRole: SystemRole;

  @Output() selected = new EventEmitter<ResolvedSystem>();

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  openSystemSearchDialog() {
    let dialogRef = this.dialog.open(SystemSearchDialog, {
      width: "800px",
      height: "600px",
      data: {
        systemRole: this.systemRole,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.selected.emit(result);
      }
    });
  }
}
