import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ResolvedSystem } from "../../models/resolved-system";
import { HvacService } from "../../../../core/hvac.service";

@Component({
  selector: "app-selected-system-card",
  templateUrl: "./selected-system-card.component.html",
  styleUrls: [
    "../system-card.component.scss",
    "./selected-system-card.component.scss",
  ],
})
export class SelectedSystemCardComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() system: ResolvedSystem;
  @Input() title: String;

  @Output() removed = new EventEmitter();

  tooltip = "";

  constructor(private hvacService: HvacService) {}

  ngOnInit(): void {}

  removeSystem() {
    this.removed.emit();
  }

  getSystemUrl() {
    return this.hvacService.getSystemUrlBySystemId(this.system.modelId);
  }

  getSystemImage(): string {
    let image: string;
    if (this.system.imageUrl !== null) {
      image = this.system.imageUrl;
    } else {
      image = "assets/images/no-model-image.svg";
    }

    return image;
  }

  getTooltip(): string {
    let tooltip = "";

    if (this.system.manufacturers) {
      const names = this.system.manufacturers.map((m) => m.name);
      tooltip += `${names.join(" / ")} - `;
    }

    tooltip += `${this.system.modelName}`;

    return tooltip;
  }
}
