<h2>Instructions</h2>

<ul class="instruction-list">
  <li
    *ngFor="let item of items"
    [routerLink]="['./devices/', item.deviceSerialNo, 'instructions', item.id]"
    [routerLinkActive]="'instruction-list-item-selected'"
    #rla="routerLinkActive"
    [ngClass]="'instruction-list-item'"
  >
    <app-icon
      class="flex-grow-0"
      [icon]="item.icon"
      [isSelected]="rla.isActive"
    ></app-icon>
    <span class="label flex-grow-1">{{ item.label }}</span>
  </li>
</ul>
