<h3 class="title">{{ title }}</h3>
<span class="text-1 multiline-ellipsis" matTooltip="{{ system.description }}">
  {{ system?.name }} - {{ system?.manufacturer }}
</span>
<span class="text-2">Unknown system</span>
<div class="button">
  <button
    mat-button
    color="warn"
    [disabled]="disabled"
    (click)="removeSystem()"
  >
    remove
  </button>
</div>
<img
  class="image"
  src="https://cidevelop.tado.com/webapp/images/setup-selector/unknown-model.58918266.svg"
  alt="System"
/>
