import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InstallationSupportComponent } from "./installation-support.component";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatDividerModule } from "@angular/material/divider";
import { MatToolbarModule } from "@angular/material/toolbar";
import { AuthModule } from "../auth/auth.module";
import { TextWithIconComponent } from "./text-with-icon/text-with-icon.component";
import { HomeDetailsComponent } from "./home-details/home-details.component";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { InstallationsListComponent } from "./installations-list/installations-list.component";
import { MatListModule } from "@angular/material/list";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { MatTableModule } from "@angular/material/table";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SystemCardComponent } from "./heating-setup/system-card/system-card.component";
import { UnknownSystemCardComponent } from "./heating-setup/system-card/unknown-system-card/unknown-system-card.component";
import { EmptyCardComponent } from "./heating-setup/system-card/empty-card/empty-card.component";
import { SelectedSystemCardComponent } from "./heating-setup/system-card/selected-system-card/selected-system-card.component";
import { MatChipsModule } from "@angular/material/chips";
import { MatDialogModule } from "@angular/material/dialog";
import { SystemSearchDialog } from "./system-search/system-search-dialog";
import { SharedModule } from "../shared/shared.module";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { InstallationEmptyStateComponent } from "./installation-empty-state/installation-empty-state.component";
import { SystemsTableComponent } from "./system-search/systems-table/systems-table.component";
import { MatInputModule } from "@angular/material/input";
import { FormsModule } from "@angular/forms";
import { MatMenuModule } from "@angular/material/menu";
import { MatStepperModule } from "@angular/material/stepper";
import { CommentsComponent } from "./comments/comments.component";
import { MarkdownModule } from "ngx-markdown";
import { MatExpansionModule } from "@angular/material/expansion";
import { InstructionsListComponent } from "./instructions-list/instructions-list.component";
import { InstructionComponent } from "./instruction/instruction.component";
import { DeleteInstructionDialogComponent } from "./instruction/delete-instruction-dialog/delete-instruction-dialog.component";
import { EditInstructionsComponent } from "./instruction/edit-instructions/edit-instructions.component";
import { ShowInstructionComponent } from "./instruction/show-instruction/show-instruction.component";

@NgModule({
  declarations: [
    InstallationSupportComponent,
    TextWithIconComponent,
    HomeDetailsComponent,
    InstructionComponent,
    DeleteInstructionDialogComponent,
    InstallationsListComponent,
    SystemCardComponent,
    UnknownSystemCardComponent,
    SelectedSystemCardComponent,
    EmptyCardComponent,
    SystemSearchDialog,
    InstallationEmptyStateComponent,
    SystemsTableComponent,
    CommentsComponent,
    InstructionsListComponent,
    EditInstructionsComponent,
    ShowInstructionComponent,
  ],
  imports: [
    CommonModule,
    MatSidenavModule,
    MatDividerModule,
    MatToolbarModule,
    AuthModule,
    MatCardModule,
    MatFormFieldModule,
    MatSelectModule,
    MatListModule,
    MatIconModule,
    RouterModule,
    MatButtonModule,
    MatTableModule,
    MatTooltipModule,
    MatChipsModule,
    MatDialogModule,
    SharedModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatInputModule,
    FormsModule,
    MatMenuModule,
    MatStepperModule,
    MatExpansionModule,
    MarkdownModule.forChild(),
  ],
  exports: [InstallationSupportComponent],
})
export class InstallationSupportModule {}
