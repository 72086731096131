<h3 class="title">{{ title }}</h3>
<span class="text-1 multiline-ellipsis"> No system selected </span>
<span class="text-2"></span>
<div class="button">
  <button
    mat-button
    color="primary"
    [disabled]="disabled"
    (click)="openSystemSearchDialog()"
  >
    select
  </button>
</div>
