<div class="flex-grow-1 flex-basis-0 mb-16">
  <mat-card class="flex-item">
    <mat-card-content>
      <div class="d-flex">
        <h3 class="mb-8">Edit Instruction</h3>
      </div>

      <div class="selected-system">
        <form class="input-form">
          <mat-form-field class="input-full-width">
            <mat-label>Install flow Id</mat-label>
            <input
              #installFlowId
              matInput
              placeholder="100.1"
              value="{{ instruction.flowEngineParameters?.installFlowId }}"
            />
          </mat-form-field>
          <mat-form-field class="input-full-width">
            <mat-label>System Id</mat-label>
            <input
              #systemId
              matInput
              placeholder="12"
              value="{{ instruction?.system.id }}"
            />
          </mat-form-field>
          <mat-form-field class="input-full-width">
            <mat-label>Boiler Id</mat-label>
            <input
              #boilerId
              matInput
              placeholder="12"
              value="{{ instruction?.boiler?.id }}"
            />
          </mat-form-field>
        </form>
        <div class="d-flex button-row">
          <div class="button mt-4">
            <button
              mat-raised-button
              color="primary"
              (click)="
                saveInstructionsFlowInfo(
                  installFlowId.value,
                  systemId.value,
                  boilerId.value
                )
              "
            >
              Save
            </button>
          </div>
          <div class="button mt-4">
            <button mat-raised-button color="warn" (click)="cancelEditing()">
              Cancel
            </button>
          </div>
        </div>
        <mat-progress-bar
          *ngIf="loading"
          color="accent"
          mode="indeterminate"
          class="mt-8"
        ></mat-progress-bar>
      </div>
    </mat-card-content>
  </mat-card>
</div>
