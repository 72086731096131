<div class="root d-flex flex-column align-items-start bg-backdrop">
  <mat-toolbar
    class="d-flex justify-content-between flex-grow-0"
    color="primary"
  >
    <span class="title">Genie - Installation Support</span>
    <app-auth-button class="auth-button"></app-auth-button>
  </mat-toolbar>

  <div
    class="
      d-flex
      flex-grow-1
      justify-content-center
      align-items-center
      page-content
    "
  >
    <h1 class="text-disabled">Please sign in</h1>
  </div>
</div>
