import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginComponent } from "./login/login.component";
import { MatButtonModule } from "@angular/material/button";
import { MatToolbarModule } from "@angular/material/toolbar";
import { AuthButtonComponent } from "./auth-button/auth-button.component";
import { InvertedStrokedButtonComponent } from "./inverted-stroked-button/inverted-stroked-button.component";
import { MatInputModule } from "@angular/material/input";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { RouterModule } from "@angular/router";

@NgModule({
  declarations: [
    LoginComponent,
    AuthButtonComponent,
    InvertedStrokedButtonComponent,
  ],
  exports: [AuthButtonComponent],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    RouterModule,
  ],
})
export class AuthModule {}
