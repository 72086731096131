<table mat-table [dataSource]="systems">
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>ID</th>
    <td mat-cell *matCellDef="let system">
      {{ system.id }}
    </td>
  </ng-container>

  <ng-container matColumnDef="manufacturers">
    <th mat-header-cell *matHeaderCellDef>Manufacturers</th>
    <td mat-cell *matCellDef="let system">
      <div *ngFor="let manufacturer of system.manufacturers">
        {{ manufacturer.name }}
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="thumbnail">
    <th mat-header-cell *matHeaderCellDef>Image</th>
    <td mat-cell *matCellDef="let system">
      <img
        *ngIf="system.thumbnail.schematic"
        mat-card-sm-image
        src="{{ system.thumbnail.schematic.url }}"
        alt="{{ system.id }}"
        class="mb-n24"
      />
    </td>
  </ng-container>

  <ng-container matColumnDef="modelName">
    <th mat-header-cell *matHeaderCellDef>Model</th>
    <td mat-cell *matCellDef="let system">
      {{ system.modelName }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: columnsToDisplay"
    (click)="selectSystem(row)"
  ></tr>
</table>
