import { Component, Input, OnInit } from "@angular/core";
import { Instruction } from "../../../../generated/instructions-api/models/instruction";
import { ResolvedSystem } from "../../heating-setup/models/resolved-system";
import { InstructionState } from "../../../../generated/instructions-api/models/instruction-state";
import { InstructionsService } from "../../../../generated/instructions-api/services/instructions.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpErrorResponse } from "@angular/common/http";
import { ResponseError } from "../../../models/response-error";
import { InstructionService } from "../../../../generated/instructions-api/services/instruction.service";
import { ActivatedRoute, Router } from "@angular/router";
import { HomeInfo } from "../../../../generated/tga-api/models/home-info";

@Component({
  selector: "app-edit-instructions",
  templateUrl: "./edit-instructions.component.html",
  styleUrls: ["./edit-instructions.component.scss"],
})
export class EditInstructionsComponent implements OnInit {
  @Input() instruction: Instruction;
  @Input() homeId: number;

  selectedSystem: ResolvedSystem;
  selectedBoiler: ResolvedSystem;

  loading = false;

  constructor(
    private instructionsService: InstructionsService,
    private instructionServiceApi: InstructionService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.selectedSystem = null;
    this.selectedBoiler = null;
  }

  saveInstructionsFlowInfo(installFlowId, systemId, boilerId) {
    const serialNumber = this.instruction.device.serialNo;
    this.loading = true;
    this.instructionsService
      .updateInstructionsFlowInfo({
        homeId: this.homeId,
        serialNo: serialNumber,
        instructionsId: this.instruction.id,
        body: {
          installFlowId: installFlowId,
          systemId: systemId,
          boilerId: boilerId,
        },
      })
      .subscribe(
        () => {
          this.loading = false;
          // Using router.navigate will not re-run the resolvers
          window.location.replace(
            `/homes/${this.homeId}/devices/${serialNumber}/instructions/${this.instruction.id}`
          );
        },
        (error: HttpErrorResponse) => {
          this.errorApiHandler(error);
        }
      );
  }

  cancelEditing() {
    this.router.navigate(["./"], {
      relativeTo: this.activatedRoute,
    });
  }

  private errorApiHandler(error: HttpErrorResponse) {
    this.loading = false;

    let errors = `HTTP error ${error.status}`;
    try {
      const errorJson = JSON.parse(error.error);
      const errorsArray = (errorJson.errors as ResponseError[])?.map(
        (responseError) => responseError.title
      );
      errors = errorsArray.join(",");
    } catch (e) {}

    this.snackBar.open(`Cannot save: ${errors}`, null, {
      duration: 7000,
    });
  }
}
