import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ResolvedSystem } from "../models/resolved-system";
import { SystemRole } from "../../../../generated/ivar/types";

export interface UpdateSystemEvent {
  systemRole: SystemRole;
  system: ResolvedSystem | null;
}

@Component({
  selector: "app-system-card",
  templateUrl: "./system-card.component.html",
  styleUrls: ["./system-card.component.scss"],
})
export class SystemCardComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() systemRole: SystemRole;
  @Input() system: ResolvedSystem | null;
  @Input() title: String;

  @Output() updated = new EventEmitter<UpdateSystemEvent>();

  constructor() {}

  ngOnInit(): void {}

  removeSystem() {
    this.system = null;
    this.updated.emit({
      systemRole: this.systemRole,
      system: this.system,
    });
  }

  addSystem(system: ResolvedSystem) {
    this.system = system;
    this.updated.emit({
      systemRole: this.systemRole,
      system: this.system,
    });
  }
}
