import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-text-with-icon",
  templateUrl: "./text-with-icon.component.html",
  styleUrls: ["./text-with-icon.component.scss"],
})
export class TextWithIconComponent implements OnInit {
  @Input() text: string;
  @Input() icon: string;

  constructor() {}

  ngOnInit() {}
}
