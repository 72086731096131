<div class="d-flex flex-column root align-items-start">
  <mat-toolbar
    class="d-flex justify-content-between flex-grow-0"
    color="primary"
  >
    <div class="d-flex align-items-center">
      <span class="title">{{ title }}</span>
    </div>
    <div class="d-flex align-items-center">
      <div class="mr-8">
        <button
          mat-icon-button
          (click)="openSupportPage()"
          matTooltip="Open support page"
        >
          <app-icon
            [size]="24"
            [icon]="'phone'"
            [isSelected]="true"
            class="d-flex justify-content-center"
          ></app-icon>
        </button>
      </div>
      <div>
        <button
          mat-icon-button
          (click)="openVisu()"
          matTooltip="Open visualization"
        >
          <app-icon
            [size]="24"
            [icon]="'timeline'"
            [isSelected]="true"
            class="d-flex justify-content-center"
          ></app-icon>
        </button>
      </div>
      <app-auth-button class="auth-button"></app-auth-button>
    </div>
  </mat-toolbar>

  <div
    class="
      d-flex
      flex-grow-1
      justify-content-center
      align-items-center
      page-content
    "
  >
    <h1 class="text-disabled">Please select an installation process</h1>
  </div>
</div>
