import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { GenericInstallation } from "../../generated/tga-api/models/generic-installation";
import { CommentInfo } from "../../generated/tga-api/models/comment-info";
import { Instruction } from "../../generated/instructions-api/models/instruction";

@Component({
  selector: "app-installation-support",
  templateUrl: "./installation-support.component.html",
  styleUrls: ["./installation-support.component.scss"],
})
export class InstallationSupportComponent implements OnInit {
  homeId: number;
  instructions: Instruction[];
  tgaInstallations: GenericInstallation[];
  customerSupportComment: CommentInfo | null;

  constructor(private routeService: ActivatedRoute) {}

  ngOnInit(): void {
    this.homeId = Number(this.routeService.snapshot.paramMap.get("homeId"));

    this.instructions = this.routeService.snapshot.data
      .instructions as Instruction[];

    this.tgaInstallations = this.routeService.snapshot.data
      .tgaInstallations as GenericInstallation[];

    this.customerSupportComment = this.routeService.snapshot.data
      .customerSupportComment as CommentInfo;
  }
}
