<mat-toolbar color="primary" class="d-flex justify-content-between">
  <span>Genie - Installation Support</span>
  <app-auth-button></app-auth-button>
</mat-toolbar>
<div>
  <form>
    <div class="d-flex flex-column align-items-center flex-margin">
      <mat-form-field appearance="outline">
        <mat-label>Home ID</mat-label>
        <input
          autofocus
          matInput
          [formControl]="homeIdControl"
          class="input"
          type="number"
          #input
          (keyup.enter)="navigateToHome(input.value)"
          placeholder="123"
        />
        <mat-error>{{ homeIdControl.getError("incorrectHomeId") }} </mat-error>
      </mat-form-field>
      <button
        class="button"
        mat-raised-button
        color="primary"
        (click)="navigateToHome(input.value)"
      >
        navigate to home
      </button>
    </div>
  </form>
</div>
