<mat-toolbar color="primary" class="d-flex justify-content-between">
  <div class="d-flex align-items-center">
    <span class="title">{{ title }}</span>
  </div>
  <div class="d-flex align-items-center">
    <div>
      <button
        mat-icon-button
        (click)="openDeleteConfirmation()"
        matTooltip="Delete instruction"
      >
        <app-icon
          [size]="24"
          [icon]="'delete'"
          [isSelected]="true"
          class="d-flex justify-content-center"
        ></app-icon>
      </button>
    </div>
    <app-auth-button class="auth-button"></app-auth-button>
  </div>
  <mat-progress-bar
    *ngIf="loading"
    color="accent"
    mode="indeterminate"
    class="position-absolute ml-n16 mt-32"
  ></mat-progress-bar>
</mat-toolbar>

<div class="d-flex flex-column p-16">
  <h2 class="mb-8">Instruction information</h2>
  <app-show-instruction
    class="d-flex flex-row justify-content-evenly align-items-start"
    *ngIf="!editModeEnabled"
    [homeId]="homeId"
    [instruction]="instruction"
    [selectedSystem]="selectedSystem"
    [selectedBoiler]="selectedBoiler"
  >
  </app-show-instruction>
  <app-edit-instructions
    *ngIf="editModeEnabled"
    [homeId]="homeId"
    [instruction]="instruction"
  >
  </app-edit-instructions>
  <div class="d-flex mb-24 justify-content-between">
    <div class="text-medium-emphasis">
      <span>Last modified {{ instruction.lastModifiedDate | customDate }} </span
      ><br />
      <span>By {{ instruction.lastModifiedBy }}</span>
    </div>
  </div>
</div>
