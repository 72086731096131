<h2 mat-dialog-title>Delete instruction?</h2>
<mat-dialog-content> </mat-dialog-content>
<div class="d-flex justify-content-end mt-24 mr-n16 ml-n16">
  <button
    mat-button
    [mat-dialog-close]="false"
    class="text-uppercase"
    color="primary"
    tabindex="-1"
  >
    Cancel
  </button>
  <button
    mat-button
    [mat-dialog-close]="true"
    class="text-uppercase"
    color="primary"
    tabindex="-1"
  >
    Delete
  </button>
</div>
