<mat-sidenav-container>
  <mat-sidenav mode="side" opened class="sidenav">
    <app-home-details [homeId]="homeId"></app-home-details>
    <mat-divider></mat-divider>
    <app-comments
      [customerSupportComment]="customerSupportComment"
    ></app-comments>
    <mat-divider></mat-divider>
    <app-instructions-list
      [instructions]="instructions"
    ></app-instructions-list>
    <mat-divider></mat-divider>
    <app-installations-list
      [tgaInstallations]="tgaInstallations"
    ></app-installations-list>
  </mat-sidenav>
  <mat-sidenav-content class="page-content">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
