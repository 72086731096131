import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { GenericSystem } from "../../../../generated/ivar/types";

@Component({
  selector: "app-systems-table",
  templateUrl: "./systems-table.component.html",
  styleUrls: ["./systems-table.component.scss"],
})
export class SystemsTableComponent implements OnInit {
  @Input() systems: GenericSystem[];
  @Output() selectedSystem = new EventEmitter<GenericSystem>();

  columnsToDisplay = ["id", "manufacturers", "thumbnail", "modelName"];

  constructor() {}

  ngOnInit(): void {}

  selectSystem(system: GenericSystem) {
    this.selectedSystem.emit(system);
  }
}
