<div class="flex-grow-1 flex-basis-0 mb-16">
  <mat-card class="flex-item">
    <mat-card-content>
      <div class="d-flex">
        <mat-chip-list>
          <app-toggle-chip
            [options]="instructionStateOptions"
            [selectedValue]="instruction.state"
            [disabled]="!canResolveYellowFlags()"
            (updated)="resolveYellowFlags($event)"
          ></app-toggle-chip>
        </mat-chip-list>
        <button
          mat-button
          matTooltip="Install flow"
          [disabled]="!isInstructionsFlowEditable()"
          (click)="openEditMode()"
        >
          <div class="d-flex align-items-center">
            <app-icon
              class="d-inline-block"
              icon="ballot"
              [size]="24"
            ></app-icon>
            <span class="ml-8 mr-8">{{
              instruction.flowEngineParameters?.installFlowId || "Not set"
            }}</span>
            <app-icon class="d-inline-block" icon="edit" [size]="24"></app-icon>
          </div>
        </button>
      </div>
      <div class="selected-system" *ngIf="selectedSystem">
        <app-system-card
          [title]="'Selected system'"
          [system]="selectedSystem"
          [disabled]="true"
          class="card-item margin-right"
        ></app-system-card>
      </div>
      <div class="selected-system" *ngIf="selectedBoiler">
        <app-system-card
          [title]="'Selected boiler'"
          [system]="selectedBoiler"
          [disabled]="true"
          class="card-item margin-right"
        ></app-system-card>
      </div>
    </mat-card-content>
  </mat-card>
</div>
