import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, of, throwError } from "rxjs";
import { InstallationServiceService } from "../../generated/tga-api/services";
import { catchError } from "rxjs/operators";
import { CommentInfo } from "../../generated/tga-api/models/comment-info";

@Injectable({ providedIn: "root" })
export class CustomerSupportCommentResolver
  implements Resolve<CommentInfo | null>
{
  constructor(private tgaInstallationService: InstallationServiceService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<CommentInfo | null> {
    const homeId = Number(route.paramMap.get("homeId"));

    return this.tgaInstallationService
      .showCustomerSupportComment({ homeId })
      .pipe(
        catchError((error) => {
          if (error.status === 404) {
            return of(null);
          }
          return throwError(error);
        })
      );
  }
}
