import { Component, Input, OnInit } from "@angular/core";
import { Instruction } from "../../../../generated/instructions-api/models/instruction";
import { ResolvedSystem } from "../../heating-setup/models/resolved-system";
import { InstructionState } from "../../../../generated/instructions-api/models/instruction-state";
import { HttpErrorResponse } from "@angular/common/http";
import { InstructionsService } from "../../../../generated/instructions-api/services/instructions.service";
import { ResponseError } from "../../../models/response-error";
import { MatSnackBar } from "@angular/material/snack-bar";
import { HomeInfo } from "../../../../generated/tga-api/models/home-info";
import { SystemRole } from "../../../../generated/ivar/types";
import { ActivatedRoute, Router } from "@angular/router";
import { InstructionType } from "../../../../generated/instructions-api/models/instruction-type";

@Component({
  selector: "app-show-instruction",
  templateUrl: "./show-instruction.component.html",
  styleUrls: ["./show-instruction.component.scss"],
})
export class ShowInstructionComponent {
  @Input() instruction: Instruction;
  @Input() homeId: number;
  @Input() selectedSystem: ResolvedSystem;
  @Input() selectedBoiler: ResolvedSystem;

  loading = false;

  title: string;

  instructionStateOptions = [
    {
      value: InstructionState.NoYf,
      label: "No Yellow Flags",
      icon: "check_circle",
      iconClass: "text-success",
    },
    {
      value: InstructionState.YfPresent,
      label: "Yellow Flags Present",
      icon: "cancel",
      iconClass: "text-danger",
    },
    {
      value: InstructionState.YfResolved,
      label: "Yellow Flags Resolved",
      icon: "check_circle",
      iconClass: "text-success",
    },
  ];

  constructor(
    private instructionsService: InstructionsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  canResolveYellowFlags(): boolean {
    return this.instruction.state === InstructionState.YfPresent;
  }

  resolveYellowFlags(state: InstructionState) {
    if (state !== InstructionState.YfResolved) {
      return;
    }
    const beforeState = this.instruction.state;
    this.instruction.state = InstructionState.YfResolved;
    this.instructionsService
      .resolveYellowFlags({
        homeId: this.homeId,
        serialNo: this.instruction.device.serialNo,
        instructionsId: this.instruction.id,
      })
      .subscribe(
        () => {
          this.successApiHandlerWithoutReload();
        },
        (error: HttpErrorResponse) => {
          this.instruction.state = beforeState;
          this.errorApiHandler(error);
        }
      );
  }

  isInstructionsFlowEditable() {
    return this.instruction?.instructionType === InstructionType.Default;
  }
  openEditMode() {
    this.router.navigate(["./"], {
      queryParams: { edit: "true" },
      relativeTo: this.activatedRoute,
    });
  }

  successApiHandlerWithoutReload() {
    this.loading = false;
    this.snackBar.open("Changes have been saved", null, {
      duration: 2000,
    });
  }

  private errorApiHandler(error: HttpErrorResponse) {
    this.loading = false;

    let errors = `HTTP error ${error.status}`;
    try {
      const errorJson = JSON.parse(error.error);
      const errorsArray = (errorJson.errors as ResponseError[])?.map(
        (responseError) => responseError.title
      );
      errors = errorsArray.join(",");
    } catch (e) {}

    this.snackBar.open(`Cannot save: ${errors}`, null, {
      duration: 7000,
    });
  }
}
