import { Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Hub } from "aws-amplify";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnChanges {
  constructor() {}

  ngOnInit(): void {
    Hub.listen("auth", (data) => {
      if (data.payload.event === "signIn") {
        // reload the page on signIn event to make content visible for authorized users
        let requestedUrl = window.localStorage.getItem("requestedUrl");
        console.log("Check request URL " + requestedUrl);
        window.localStorage.removeItem("requestedUrl");
        if (requestedUrl === null) {
          window.location.replace("/landing");
        } else {
          window.location.replace(requestedUrl);
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {}
}
