/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BridgeInstructionsInput } from '../models/bridge-instructions-input';
import { Instruction } from '../models/instruction';
import { InstructionList } from '../models/instruction-list';
import { InstructionsFlowInput } from '../models/instructions-flow-input';
import { InstructionsInput } from '../models/instructions-input';


/**
 * Instructions API
 */
@Injectable({
  providedIn: 'root',
})
export class InstructionsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation listHomeInstructions
   */
  static readonly ListHomeInstructionsPath = '/homes/{homeId}/instructions';

  /**
   * Get instructions for home
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listHomeInstructions()` instead.
   *
   * This method doesn't expect any request body.
   */
  listHomeInstructions$Response(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

  }): Observable<StrictHttpResponse<InstructionList>> {

    const rb = new RequestBuilder(this.rootUrl, InstructionsService.ListHomeInstructionsPath, 'get');
    if (params) {

      rb.path('homeId', params.homeId, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InstructionList>;
      })
    );
  }

  /**
   * Get instructions for home
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listHomeInstructions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listHomeInstructions(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

  }): Observable<InstructionList> {

    return this.listHomeInstructions$Response(params).pipe(
      map((r: StrictHttpResponse<InstructionList>) => r.body as InstructionList)
    );
  }

  /**
   * Path part for operation listInstructions
   */
  static readonly ListInstructionsPath = '/homes/{homeId}/devices/{serialNo}/instructions';

  /**
   * Get instructions for specified device
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listInstructions()` instead.
   *
   * This method doesn't expect any request body.
   */
  listInstructions$Response(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

    /**
     * tado device serial number
     */
    serialNo: string;

  }): Observable<StrictHttpResponse<InstructionList>> {

    const rb = new RequestBuilder(this.rootUrl, InstructionsService.ListInstructionsPath, 'get');
    if (params) {

      rb.path('homeId', params.homeId, {});
      rb.path('serialNo', params.serialNo, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InstructionList>;
      })
    );
  }

  /**
   * Get instructions for specified device
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listInstructions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listInstructions(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

    /**
     * tado device serial number
     */
    serialNo: string;

  }): Observable<InstructionList> {

    return this.listInstructions$Response(params).pipe(
      map((r: StrictHttpResponse<InstructionList>) => r.body as InstructionList)
    );
  }

  /**
   * Path part for operation createOrUpdateInstructions
   */
  static readonly CreateOrUpdateInstructionsPath = '/homes/{homeId}/devices/{serialNo}/instructions';

  /**
   * Update instructions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrUpdateInstructions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrUpdateInstructions$Response(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

    /**
     * tado device serial number
     */
    serialNo: string;
      body?: InstructionsInput
  }): Observable<StrictHttpResponse<Instruction>> {

    const rb = new RequestBuilder(this.rootUrl, InstructionsService.CreateOrUpdateInstructionsPath, 'put');
    if (params) {

      rb.path('homeId', params.homeId, {});
      rb.path('serialNo', params.serialNo, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Instruction>;
      })
    );
  }

  /**
   * Update instructions
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createOrUpdateInstructions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrUpdateInstructions(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

    /**
     * tado device serial number
     */
    serialNo: string;
      body?: InstructionsInput
  }): Observable<Instruction> {

    return this.createOrUpdateInstructions$Response(params).pipe(
      map((r: StrictHttpResponse<Instruction>) => r.body as Instruction)
    );
  }

  /**
   * Path part for operation deleteInstructions
   */
  static readonly DeleteInstructionsPath = '/homes/{homeId}/devices/{serialNo}/instructions';

  /**
   * Delete instructions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteInstructions()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteInstructions$Response(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

    /**
     * tado device serial number
     */
    serialNo: string;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, InstructionsService.DeleteInstructionsPath, 'delete');
    if (params) {

      rb.path('homeId', params.homeId, {});
      rb.path('serialNo', params.serialNo, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete instructions
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteInstructions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteInstructions(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

    /**
     * tado device serial number
     */
    serialNo: string;

  }): Observable<void> {

    return this.deleteInstructions$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateInstructionsFlowInfo
   */
  static readonly UpdateInstructionsFlowInfoPath = '/homes/{homeId}/devices/{serialNo}/instructions/{instructionsId}/flowInfo';

  /**
   * Update instructions flow id and system information
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateInstructionsFlowInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateInstructionsFlowInfo$Response(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

    /**
     * tado device serial number
     */
    serialNo: string;

    /**
     * instructions UUID
     */
    instructionsId: string;
      body?: InstructionsFlowInput
  }): Observable<StrictHttpResponse<Instruction>> {

    const rb = new RequestBuilder(this.rootUrl, InstructionsService.UpdateInstructionsFlowInfoPath, 'put');
    if (params) {

      rb.path('homeId', params.homeId, {});
      rb.path('serialNo', params.serialNo, {});
      rb.path('instructionsId', params.instructionsId, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Instruction>;
      })
    );
  }

  /**
   * Update instructions flow id and system information
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateInstructionsFlowInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateInstructionsFlowInfo(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

    /**
     * tado device serial number
     */
    serialNo: string;

    /**
     * instructions UUID
     */
    instructionsId: string;
      body?: InstructionsFlowInput
  }): Observable<Instruction> {

    return this.updateInstructionsFlowInfo$Response(params).pipe(
      map((r: StrictHttpResponse<Instruction>) => r.body as Instruction)
    );
  }

  /**
   * Path part for operation createOrUpdateTemperatureSensorInstructions
   */
  static readonly CreateOrUpdateTemperatureSensorInstructionsPath = '/homes/{homeId}/devices/{serialNo}/instructions/temperaturesensor';

  /**
   * Update/create instructions for a temperature sensor
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrUpdateTemperatureSensorInstructions()` instead.
   *
   * This method doesn't expect any request body.
   */
  createOrUpdateTemperatureSensorInstructions$Response(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

    /**
     * tado device serial number
     */
    serialNo: string;

  }): Observable<StrictHttpResponse<Instruction>> {

    const rb = new RequestBuilder(this.rootUrl, InstructionsService.CreateOrUpdateTemperatureSensorInstructionsPath, 'put');
    if (params) {

      rb.path('homeId', params.homeId, {});
      rb.path('serialNo', params.serialNo, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Instruction>;
      })
    );
  }

  /**
   * Update/create instructions for a temperature sensor
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createOrUpdateTemperatureSensorInstructions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createOrUpdateTemperatureSensorInstructions(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

    /**
     * tado device serial number
     */
    serialNo: string;

  }): Observable<Instruction> {

    return this.createOrUpdateTemperatureSensorInstructions$Response(params).pipe(
      map((r: StrictHttpResponse<Instruction>) => r.body as Instruction)
    );
  }

  /**
   * Path part for operation createOrUpdateBridgingInstructions
   */
  static readonly CreateOrUpdateBridgingInstructionsPath = '/homes/{homeId}/devices/{serialNo}/instructions/bridging';

  /**
   * Update/create instructions for a bridging the room thermostat
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrUpdateBridgingInstructions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrUpdateBridgingInstructions$Response(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

    /**
     * tado device serial number
     */
    serialNo: string;
      body?: BridgeInstructionsInput
  }): Observable<StrictHttpResponse<Instruction>> {

    const rb = new RequestBuilder(this.rootUrl, InstructionsService.CreateOrUpdateBridgingInstructionsPath, 'put');
    if (params) {

      rb.path('homeId', params.homeId, {});
      rb.path('serialNo', params.serialNo, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Instruction>;
      })
    );
  }

  /**
   * Update/create instructions for a bridging the room thermostat
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createOrUpdateBridgingInstructions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrUpdateBridgingInstructions(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

    /**
     * tado device serial number
     */
    serialNo: string;
      body?: BridgeInstructionsInput
  }): Observable<Instruction> {

    return this.createOrUpdateBridgingInstructions$Response(params).pipe(
      map((r: StrictHttpResponse<Instruction>) => r.body as Instruction)
    );
  }

  /**
   * Path part for operation createOrUpdateRadiatorThermostatInstructions
   */
  static readonly CreateOrUpdateRadiatorThermostatInstructionsPath = '/homes/{homeId}/devices/{serialNo}/instructions/radiatorThermostat';

  /**
   * Update/create instructions for a smart radiator thermostat (SRT)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrUpdateRadiatorThermostatInstructions()` instead.
   *
   * This method doesn't expect any request body.
   */
  createOrUpdateRadiatorThermostatInstructions$Response(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

    /**
     * tado device serial number
     */
    serialNo: string;

  }): Observable<StrictHttpResponse<Instruction>> {

    const rb = new RequestBuilder(this.rootUrl, InstructionsService.CreateOrUpdateRadiatorThermostatInstructionsPath, 'put');
    if (params) {

      rb.path('homeId', params.homeId, {});
      rb.path('serialNo', params.serialNo, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Instruction>;
      })
    );
  }

  /**
   * Update/create instructions for a smart radiator thermostat (SRT)
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createOrUpdateRadiatorThermostatInstructions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createOrUpdateRadiatorThermostatInstructions(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

    /**
     * tado device serial number
     */
    serialNo: string;

  }): Observable<Instruction> {

    return this.createOrUpdateRadiatorThermostatInstructions$Response(params).pipe(
      map((r: StrictHttpResponse<Instruction>) => r.body as Instruction)
    );
  }

  /**
   * Path part for operation getDeviceInstruction
   */
  static readonly GetDeviceInstructionPath = '/homes/{homeId}/devices/{serialNo}/instructions/{instructionsId}';

  /**
   * Get instruction
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDeviceInstruction()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeviceInstruction$Response(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

    /**
     * tado device serial number
     */
    serialNo: string;

    /**
     * instructions UUID
     */
    instructionsId: string;

  }): Observable<StrictHttpResponse<Instruction>> {

    const rb = new RequestBuilder(this.rootUrl, InstructionsService.GetDeviceInstructionPath, 'get');
    if (params) {

      rb.path('homeId', params.homeId, {});
      rb.path('serialNo', params.serialNo, {});
      rb.path('instructionsId', params.instructionsId, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Instruction>;
      })
    );
  }

  /**
   * Get instruction
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDeviceInstruction$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeviceInstruction(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

    /**
     * tado device serial number
     */
    serialNo: string;

    /**
     * instructions UUID
     */
    instructionsId: string;

  }): Observable<Instruction> {

    return this.getDeviceInstruction$Response(params).pipe(
      map((r: StrictHttpResponse<Instruction>) => r.body as Instruction)
    );
  }

  /**
   * Path part for operation setInstructionStatusToReady
   */
  static readonly SetInstructionStatusToReadyPath = '/homes/{homeId}/devices/{serialNo}/instructions/{instructionsId}/status/ready';

  /**
   * Change status of the instruction to READY
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setInstructionStatusToReady()` instead.
   *
   * This method doesn't expect any request body.
   */
  setInstructionStatusToReady$Response(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

    /**
     * tado device serial number
     */
    serialNo: string;

    /**
     * instructions UUID
     */
    instructionsId: string;

  }): Observable<StrictHttpResponse<Instruction>> {

    const rb = new RequestBuilder(this.rootUrl, InstructionsService.SetInstructionStatusToReadyPath, 'put');
    if (params) {

      rb.path('homeId', params.homeId, {});
      rb.path('serialNo', params.serialNo, {});
      rb.path('instructionsId', params.instructionsId, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Instruction>;
      })
    );
  }

  /**
   * Change status of the instruction to READY
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setInstructionStatusToReady$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setInstructionStatusToReady(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

    /**
     * tado device serial number
     */
    serialNo: string;

    /**
     * instructions UUID
     */
    instructionsId: string;

  }): Observable<Instruction> {

    return this.setInstructionStatusToReady$Response(params).pipe(
      map((r: StrictHttpResponse<Instruction>) => r.body as Instruction)
    );
  }

  /**
   * Path part for operation resolveYellowFlags
   */
  static readonly ResolveYellowFlagsPath = '/homes/{homeId}/devices/{serialNo}/instructions/{instructionsId}/resolve-yellow-flags';

  /**
   * Resolve yellow flags
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resolveYellowFlags()` instead.
   *
   * This method doesn't expect any request body.
   */
  resolveYellowFlags$Response(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

    /**
     * tado device serial number
     */
    serialNo: string;

    /**
     * instructions UUID
     */
    instructionsId: string;

  }): Observable<StrictHttpResponse<Instruction>> {

    const rb = new RequestBuilder(this.rootUrl, InstructionsService.ResolveYellowFlagsPath, 'put');
    if (params) {

      rb.path('homeId', params.homeId, {});
      rb.path('serialNo', params.serialNo, {});
      rb.path('instructionsId', params.instructionsId, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Instruction>;
      })
    );
  }

  /**
   * Resolve yellow flags
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resolveYellowFlags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resolveYellowFlags(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

    /**
     * tado device serial number
     */
    serialNo: string;

    /**
     * instructions UUID
     */
    instructionsId: string;

  }): Observable<Instruction> {

    return this.resolveYellowFlags$Response(params).pipe(
      map((r: StrictHttpResponse<Instruction>) => r.body as Instruction)
    );
  }

  /**
   * Path part for operation createCompatibilityReviewTicket
   */
  static readonly CreateCompatibilityReviewTicketPath = '/homes/{homeId}/devices/{serialNo}/instructions/{instructionsId}/compatibilityReviewTicket';

  /**
   * Create a ticket to review compatibillity of installation setup
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCompatibilityReviewTicket()` instead.
   *
   * This method doesn't expect any request body.
   */
  createCompatibilityReviewTicket$Response(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

    /**
     * tado device serial number
     */
    serialNo: string;

    /**
     * instructions UUID
     */
    instructionsId: string;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, InstructionsService.CreateCompatibilityReviewTicketPath, 'post');
    if (params) {

      rb.path('homeId', params.homeId, {});
      rb.path('serialNo', params.serialNo, {});
      rb.path('instructionsId', params.instructionsId, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Create a ticket to review compatibillity of installation setup
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createCompatibilityReviewTicket$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createCompatibilityReviewTicket(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

    /**
     * tado device serial number
     */
    serialNo: string;

    /**
     * instructions UUID
     */
    instructionsId: string;

  }): Observable<void> {

    return this.createCompatibilityReviewTicket$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation createInstructionsRequestTicket
   */
  static readonly CreateInstructionsRequestTicketPath = '/homes/{homeId}/devices/{serialNo}/instructions/{instructionsId}/instructionsRequestTicket';

  /**
   * Create a ticket to request instructions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createInstructionsRequestTicket()` instead.
   *
   * This method doesn't expect any request body.
   */
  createInstructionsRequestTicket$Response(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

    /**
     * tado device serial number
     */
    serialNo: string;

    /**
     * instructions UUID
     */
    instructionsId: string;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, InstructionsService.CreateInstructionsRequestTicketPath, 'post');
    if (params) {

      rb.path('homeId', params.homeId, {});
      rb.path('serialNo', params.serialNo, {});
      rb.path('instructionsId', params.instructionsId, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Create a ticket to request instructions
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createInstructionsRequestTicket$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createInstructionsRequestTicket(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

    /**
     * tado device serial number
     */
    serialNo: string;

    /**
     * instructions UUID
     */
    instructionsId: string;

  }): Observable<void> {

    return this.createInstructionsRequestTicket$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
