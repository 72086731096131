import { NgModule } from "@angular/core";
import { LoginComponent } from "./auth/login/login.component";
import { UnauthGuard } from "./auth/unauth.guard";
import { AuthGuard } from "./auth/auth.guard";
import { InstallationSupportComponent } from "./installation-support/installation-support.component";
import { HomeInfoResolver } from "./resolver/home-info";
import { TgaInstallationsResolver } from "./resolver/tga-installations";
import { InstallationEmptyStateComponent } from "./installation-support/installation-empty-state/installation-empty-state.component";
import { LandingComponent } from "./landing/landing.component";
import { CustomerSupportCommentResolver } from "./resolver/customer-support-comment";
import { InstructionsResolver } from "./resolver/instructions";
import { InstructionResolver } from "./resolver/instruction";
import { InstructionComponent } from "./installation-support/instruction/instruction.component";
import { EditInstructionsComponent } from "./installation-support/instruction/edit-instructions/edit-instructions.component";
import { ShowInstructionComponent } from "./installation-support/instruction/show-instruction/show-instruction.component";
import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "homes/:homeId",
    component: InstallationSupportComponent,
    canActivate: [AuthGuard],
    resolve: {
      instructions: InstructionsResolver,
      tgaInstallations: TgaInstallationsResolver,
      homeInfo: HomeInfoResolver,
      customerSupportComment: CustomerSupportCommentResolver,
    },
    children: [
      {
        path: "",
        component: InstallationEmptyStateComponent,
        canActivate: [AuthGuard],
        runGuardsAndResolvers: "always",
      },
      {
        path: "devices/:deviceSerialNo/instructions/:instructionId",
        component: InstructionComponent,
        canActivate: [AuthGuard],
        resolve: {
          instruction: InstructionResolver,
        },
        children: [
          {
            path: "",
            component: ShowInstructionComponent,
          },
          {
            path: "edit",
            component: EditInstructionsComponent,
          },
        ],
      },
    ],
  },
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "login",
    component: LoginComponent,
    pathMatch: "full",
    canActivate: [UnauthGuard],
  },
  {
    path: "landing",
    component: LandingComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
  providers: [
    InstructionResolver,
    InstructionsResolver,
    TgaInstallationsResolver,
    CustomerSupportCommentResolver,
  ],
})
export class AppRoutingModule {}
