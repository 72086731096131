import { NgModule } from "@angular/core";
import { IconComponent } from "./icon/icon.component";
import { MatIconModule } from "@angular/material/icon";
import { HttpClientModule } from "@angular/common/http";
import { CommonModule } from "@angular/common";
import { MatChipsModule } from "@angular/material/chips";
import { ToggleChipComponent } from "./toggle-chip/toggle-chip.component";
import { CustomDatePipe } from "./custom-date.pipe";

@NgModule({
  declarations: [IconComponent, ToggleChipComponent, CustomDatePipe],
  imports: [HttpClientModule, MatIconModule, CommonModule, MatChipsModule],
  exports: [IconComponent, ToggleChipComponent, CustomDatePipe],
})
export class SharedModule {}
