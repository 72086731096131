import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-inverted-stroked-button",
  templateUrl: "./inverted-stroked-button.component.html",
  styleUrls: ["./inverted-stroked-button.component.scss"],
})
export class InvertedStrokedButtonComponent implements OnInit {
  @Input() text: string;

  constructor() {}

  ngOnInit(): void {}
}
