/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GenericInstallation } from '../models/generic-installation';

@Injectable({
  providedIn: 'root',
})
export class InstallationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation listInstallations
   */
  static readonly ListInstallationsPath = '/proxy/tga/homes/{homeId}/installations';

  /**
   * Lists all installations of a home.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listInstallations()` instead.
   *
   * This method doesn't expect any request body.
   */
  listInstallations$Response(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

  }): Observable<StrictHttpResponse<Array<GenericInstallation>>> {

    const rb = new RequestBuilder(this.rootUrl, InstallationService.ListInstallationsPath, 'get');
    if (params) {

      rb.path('homeId', params.homeId, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GenericInstallation>>;
      })
    );
  }

  /**
   * Lists all installations of a home.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listInstallations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listInstallations(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

  }): Observable<Array<GenericInstallation>> {

    return this.listInstallations$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GenericInstallation>>) => r.body as Array<GenericInstallation>)
    );
  }

}
