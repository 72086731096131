import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { InstructionsService } from "../../generated/instructions-api/services/instructions.service";
import { Instruction } from "../../generated/instructions-api/models/instruction";

@Injectable()
export class InstructionsResolver implements Resolve<Instruction[]> {
  constructor(private service: InstructionsService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Instruction[]> {
    return this.service.listHomeInstructions({
      homeId: parseInt(route.paramMap.get("homeId")),
    });
  }
}
