import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";
import { PublicService } from "../../generated/tga-api/services/public.service";
import { Observable } from "rxjs";
import { HomeInfo } from "../../generated/tga-api/models/home-info";

@Injectable({ providedIn: "root" })
export class HomeInfoResolver implements Resolve<HomeInfo> {
  constructor(private tgaPublicService: PublicService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<HomeInfo> {
    const homeId = Number(route.paramMap.get("homeId"));

    return this.tgaPublicService.showHome({ homeId });
  }
}
