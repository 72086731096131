/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FlowInitializationInput } from '../models/flow-initialization-input';
import { FlowLocale } from '../models/flow-locale';
import { FlowNextStepInput } from '../models/flow-next-step-input';
import { FlowStep } from '../models/flow-step';

@Injectable({
  providedIn: 'root',
})
export class FlowService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation startFlow
   */
  static readonly StartFlowPath = '/homes/{homeId}/devices/{serialNo}/instructions/{instructionsId}/flow';

  /**
   * Re-initializes and start the flow.
   *
   * The flow is initialized and started.
   * This will return the first step of the flow.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startFlow()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  startFlow$Response(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

    /**
     * tado device serial number
     */
    serialNo: string;

    /**
     * instructions UUID
     */
    instructionsId: string;
      body: FlowInitializationInput
  }): Observable<StrictHttpResponse<FlowStep>> {

    const rb = new RequestBuilder(this.rootUrl, FlowService.StartFlowPath, 'put');
    if (params) {

      rb.path('homeId', params.homeId, {});
      rb.path('serialNo', params.serialNo, {});
      rb.path('instructionsId', params.instructionsId, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FlowStep>;
      })
    );
  }

  /**
   * Re-initializes and start the flow.
   *
   * The flow is initialized and started.
   * This will return the first step of the flow.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `startFlow$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  startFlow(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

    /**
     * tado device serial number
     */
    serialNo: string;

    /**
     * instructions UUID
     */
    instructionsId: string;
      body: FlowInitializationInput
  }): Observable<FlowStep> {

    return this.startFlow$Response(params).pipe(
      map((r: StrictHttpResponse<FlowStep>) => r.body as FlowStep)
    );
  }

  /**
   * Path part for operation getCurrentStep
   */
  static readonly GetCurrentStepPath = '/homes/{homeId}/devices/{serialNo}/instructions/{instructionsId}/flow/getCurrentStep';

  /**
   * Get current step of installation flow (flow initalized if needed).
   *
   * Get current step of installation flow. If flow has not been initialized yet,
   * the flow is initialized and this will return the first step.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentStep()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCurrentStep$Response(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

    /**
     * tado device serial number
     */
    serialNo: string;

    /**
     * instructions UUID
     */
    instructionsId: string;
      body: FlowLocale
  }): Observable<StrictHttpResponse<FlowStep>> {

    const rb = new RequestBuilder(this.rootUrl, FlowService.GetCurrentStepPath, 'post');
    if (params) {

      rb.path('homeId', params.homeId, {});
      rb.path('serialNo', params.serialNo, {});
      rb.path('instructionsId', params.instructionsId, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FlowStep>;
      })
    );
  }

  /**
   * Get current step of installation flow (flow initalized if needed).
   *
   * Get current step of installation flow. If flow has not been initialized yet,
   * the flow is initialized and this will return the first step.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCurrentStep$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCurrentStep(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

    /**
     * tado device serial number
     */
    serialNo: string;

    /**
     * instructions UUID
     */
    instructionsId: string;
      body: FlowLocale
  }): Observable<FlowStep> {

    return this.getCurrentStep$Response(params).pipe(
      map((r: StrictHttpResponse<FlowStep>) => r.body as FlowStep)
    );
  }

  /**
   * Path part for operation traverseToPreviousStep
   */
  static readonly TraverseToPreviousStepPath = '/homes/{homeId}/devices/{serialNo}/instructions/{instructionsId}/flow/traverseToPreviousStep';

  /**
   * Traverse to previous step of installation flow.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `traverseToPreviousStep()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  traverseToPreviousStep$Response(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

    /**
     * tado device serial number
     */
    serialNo: string;

    /**
     * instructions UUID
     */
    instructionsId: string;
      body: FlowLocale
  }): Observable<StrictHttpResponse<FlowStep>> {

    const rb = new RequestBuilder(this.rootUrl, FlowService.TraverseToPreviousStepPath, 'post');
    if (params) {

      rb.path('homeId', params.homeId, {});
      rb.path('serialNo', params.serialNo, {});
      rb.path('instructionsId', params.instructionsId, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FlowStep>;
      })
    );
  }

  /**
   * Traverse to previous step of installation flow.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `traverseToPreviousStep$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  traverseToPreviousStep(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

    /**
     * tado device serial number
     */
    serialNo: string;

    /**
     * instructions UUID
     */
    instructionsId: string;
      body: FlowLocale
  }): Observable<FlowStep> {

    return this.traverseToPreviousStep$Response(params).pipe(
      map((r: StrictHttpResponse<FlowStep>) => r.body as FlowStep)
    );
  }

  /**
   * Path part for operation traverseToNextStep
   */
  static readonly TraverseToNextStepPath = '/homes/{homeId}/devices/{serialNo}/instructions/{instructionsId}/flow/traverseToNextStep';

  /**
   * Traverse to next step of installation flow.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `traverseToNextStep()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  traverseToNextStep$Response(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

    /**
     * tado device serial number
     */
    serialNo: string;

    /**
     * instructions UUID
     */
    instructionsId: string;
      body: FlowNextStepInput
  }): Observable<StrictHttpResponse<FlowStep>> {

    const rb = new RequestBuilder(this.rootUrl, FlowService.TraverseToNextStepPath, 'post');
    if (params) {

      rb.path('homeId', params.homeId, {});
      rb.path('serialNo', params.serialNo, {});
      rb.path('instructionsId', params.instructionsId, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FlowStep>;
      })
    );
  }

  /**
   * Traverse to next step of installation flow.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `traverseToNextStep$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  traverseToNextStep(params: {

    /**
     * Numeric unique ID of the home
     */
    homeId: number;

    /**
     * tado device serial number
     */
    serialNo: string;

    /**
     * instructions UUID
     */
    instructionsId: string;
      body: FlowNextStepInput
  }): Observable<FlowStep> {

    return this.traverseToNextStep$Response(params).pipe(
      map((r: StrictHttpResponse<FlowStep>) => r.body as FlowStep)
    );
  }

}
