import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthModule } from "./auth/auth.module";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { InstallationSupportModule } from "./installation-support/installation-support.module";
import { environment } from "../environments/environment";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AuthInterceptor } from "./auth/auth.interceptor";
import { TgaApiModule } from "../generated/tga-api/tga-api.module";
import { GraphQLModule } from "./graphql.module";
import { LandingModule } from "./landing/landing.module";
import { MarkdownModule } from "ngx-markdown";
import { InstructionsApiModule } from "../generated/instructions-api/instructions-api.module";

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    MatToolbarModule,
    BrowserAnimationsModule,
    InstallationSupportModule,
    LandingModule,
    HttpClientModule,
    TgaApiModule.forRoot({
      rootUrl: environment.tga.apiUrl,
    }),
    InstructionsApiModule.forRoot({
      rootUrl: environment.genie.instructionsUrl,
    }),
    GraphQLModule,
    MarkdownModule.forRoot(),
  ],
  providers: [httpInterceptorProviders],
  bootstrap: [AppComponent],
})
export class AppModule {}
