import { Component, Input, OnInit } from "@angular/core";
import { PublicService } from "../../../generated/tga-api/services/public.service";
import { ActivatedRoute } from "@angular/router";
import { HomeInfo } from "../../../generated/tga-api/models/home-info";
import { Address } from "../../../generated/tga-api/models";

@Component({
  selector: "app-home-details",
  templateUrl: "./home-details.component.html",
  styleUrls: ["./home-details.component.scss"],
})
export class HomeDetailsComponent implements OnInit {
  @Input() homeId: number;
  contactName: string;
  email: string;
  address: string;

  constructor(
    private tgaPublicService: PublicService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.parseHomeInfo();

    window.document.title = this.contactName;
  }

  private parseHomeInfo(): void {
    const homeInfo = this.route.snapshot.data.homeInfo as HomeInfo;
    this.homeId = Number(homeInfo.id);
    this.email = homeInfo.contactDetails.email;
    this.contactName = homeInfo.contactDetails.name;

    this.address = this.formatAddressString(homeInfo.address);
  }

  private formatAddressString(address: Address): string {
    let addressString = `${address.addressLine1}, `;

    if (address.addressLine2) {
      addressString += `${address.addressLine2}, `;
    }

    addressString += `${address.zipCode} ${address.city}, ${address.country}`;

    return addressString;
  }
}
