import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { FormControl, Validators } from "@angular/forms";

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"],
})
export class LandingComponent implements OnInit {
  constructor(private router: Router) {}

  homeIdControl = new FormControl("", [Validators.required]);

  async navigateToHome(homeId: string): Promise<void> {
    try {
      await this.router.navigate([`homes/${homeId}`]);
      this.homeIdControl.setErrors(null);
    } catch (e) {
      if (e.status && e.status === 401) {
        this.homeIdControl.setErrors({
          incorrectHomeId: "Access denied. Please contact the Red Server team.",
        });
      } else {
        this.homeIdControl.setErrors({ incorrectHomeId: "Incorrect Home ID" });
      }
    }
  }

  ngOnInit(): void {}
}
