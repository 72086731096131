<h2>Installations</h2>

<ul class="installation-list">
  <li
    *ngFor="let item of tgaItems"
    [ngClass]="'installation-list-item'"
    (click)="navigateToTga(item)"
  >
    <app-icon class="flex-grow-0" [icon]="item.icon"></app-icon>
    <span class="label flex-grow-1">{{ item.label }}</span>
    <app-icon class="flex-grow-0" [icon]="'open_in_new'"></app-icon>
  </li>
</ul>
