import { Component, OnInit } from "@angular/core";
import { HomeInfo } from "../../../generated/tga-api/models/home-info";
import { ActivatedRoute } from "@angular/router";
import { TgaService } from "src/app/core/tga.service";

@Component({
  selector: "app-installation-empty-state",
  templateUrl: "./installation-empty-state.component.html",
  styleUrls: ["./installation-empty-state.component.scss"],
})
export class InstallationEmptyStateComponent implements OnInit {
  title: string;

  private homeId: number;

  constructor(
    private routeService: ActivatedRoute,
    private tgaService: TgaService
  ) {}

  ngOnInit(): void {
    const homeInfo = this.routeService.snapshot.data.homeInfo as HomeInfo;
    this.homeId = Number(homeInfo.id);

    this.title = homeInfo.contactDetails.name;
  }

  openSupportPage(): void {
    const url = this.tgaService.getSupportUrl(this.homeId);
    window.open(url, "_blank");
  }

  openVisu(): void {
    const url = this.tgaService.getVisuUrl(this.homeId);
    window.open(url, "_blank");
  }
}
