export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AuxiliaryControl = ControlSystem & GenericSystem & {
  __typename?: 'AuxiliaryControl';
  id: Scalars['ID'];
  modelName: Scalars['String'];
  versionName: Scalars['String'];
  verboseNames: Array<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<GenericSystemImages>;
  manufacturers: Array<Manufacturer>;
  yellowFlags: Array<YellowFlag>;
  interfaceConfigurations: Array<InterfaceConfiguration>;
  tags: Array<Tag>;
  inventory?: Maybe<Inventory>;
  powerSupply?: Maybe<PowerSupplyType>;
};


export type AuxiliaryControlModelNameArgs = {
  type?: Maybe<NameType>;
};

export type Boiler = GenericSystem & ControlSystem & {
  __typename?: 'Boiler';
  id: Scalars['ID'];
  modelName: Scalars['String'];
  versionName: Scalars['String'];
  verboseNames: Array<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<GenericSystemImages>;
  manufacturers: Array<Manufacturer>;
  yellowFlags: Array<YellowFlag>;
  interfaceConfigurations: Array<InterfaceConfiguration>;
  tags: Array<Tag>;
  inventory?: Maybe<Inventory>;
  powerSupply?: Maybe<PowerSupplyType>;
  placement?: Maybe<BoilerControlPlacement>;
  furnace?: Maybe<Furnace>;
};


export type BoilerModelNameArgs = {
  type?: Maybe<NameType>;
};

export type BoilerControl = GenericSystem & ControlSystem & {
  __typename?: 'BoilerControl';
  id: Scalars['ID'];
  modelName: Scalars['String'];
  versionName: Scalars['String'];
  verboseNames: Array<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<GenericSystemImages>;
  manufacturers: Array<Manufacturer>;
  yellowFlags: Array<YellowFlag>;
  interfaceConfigurations: Array<InterfaceConfiguration>;
  tags: Array<Tag>;
  inventory?: Maybe<Inventory>;
  powerSupply?: Maybe<PowerSupplyType>;
  placement?: Maybe<BoilerControlPlacement>;
  heatingType: HeatingType;
  heatPumpType?: Maybe<HeatPumpType>;
  combinedSystems: Array<BoilerControlWithFitsIn>;
  fitInAvailability: FitInAvailability;
};


export type BoilerControlModelNameArgs = {
  type?: Maybe<NameType>;
};

export enum BoilerControlPlacement {
  External = 'EXTERNAL',
  Integrated = 'INTEGRATED',
  Attachable = 'ATTACHABLE',
  ElectricalCabinet = 'ELECTRICAL_CABINET'
}

export type BoilerControlWithFitsIn = GenericSystem & ControlSystem & {
  __typename?: 'BoilerControlWithFitsIn';
  id: Scalars['ID'];
  modelName: Scalars['String'];
  versionName: Scalars['String'];
  verboseNames: Array<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<GenericSystemImages>;
  manufacturers: Array<Manufacturer>;
  yellowFlags: Array<YellowFlag>;
  interfaceConfigurations: Array<InterfaceConfiguration>;
  tags: Array<Tag>;
  inventory?: Maybe<Inventory>;
  powerSupply?: Maybe<PowerSupplyType>;
  placement?: Maybe<BoilerControlPlacement>;
  heatingType: HeatingType;
  heatPumpType?: Maybe<HeatPumpType>;
  fitIn: GenericSystem;
  boilerControl: GenericSystem;
};


export type BoilerControlWithFitsInModelNameArgs = {
  type?: Maybe<NameType>;
};

export type Connector = {
  __typename?: 'Connector';
  name?: Maybe<Scalars['String']>;
  configuration: Array<ConnectorWire>;
};

export type ConnectorWire = {
  __typename?: 'ConnectorWire';
  field: Scalars['String'];
  value: Scalars['String'];
};

export type ControlBoard = GenericSystem & ControlSystem & {
  __typename?: 'ControlBoard';
  id: Scalars['ID'];
  modelName: Scalars['String'];
  versionName: Scalars['String'];
  verboseNames: Array<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<GenericSystemImages>;
  manufacturers: Array<Manufacturer>;
  yellowFlags: Array<YellowFlag>;
  interfaceConfigurations: Array<InterfaceConfiguration>;
  tags: Array<Tag>;
  inventory?: Maybe<Inventory>;
  powerSupply?: Maybe<PowerSupplyType>;
  placement?: Maybe<BoilerControlPlacement>;
};


export type ControlBoardModelNameArgs = {
  type?: Maybe<NameType>;
};

export type ControlSystem = {
  powerSupply?: Maybe<PowerSupplyType>;
};

export type DateRangeInput = {
  from?: Maybe<Scalars['Float']>;
  to?: Maybe<Scalars['Float']>;
};

/**
 * Images that can be used to display a schematic image, e.g. an svg or high quality logo, and a picture from real life.
 * The real life image is usually a photo taken.
 */
export type DisplayImages = {
  __typename?: 'DisplayImages';
  schematic?: Maybe<Image>;
  realLife?: Maybe<Image>;
};

export type EffectiveBoilerControl = GenericSystem & ControlSystem & {
  __typename?: 'EffectiveBoilerControl';
  id: Scalars['ID'];
  modelName: Scalars['String'];
  versionName: Scalars['String'];
  verboseNames: Array<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<GenericSystemImages>;
  manufacturers: Array<Manufacturer>;
  yellowFlags: Array<YellowFlag>;
  interfaceConfigurations: Array<InterfaceConfiguration>;
  tags: Array<Tag>;
  inventory?: Maybe<Inventory>;
  powerSupply?: Maybe<PowerSupplyType>;
  placement?: Maybe<BoilerControlPlacement>;
  heatingType: HeatingType;
  heatPumpType?: Maybe<HeatPumpType>;
};


export type EffectiveBoilerControlModelNameArgs = {
  type?: Maybe<NameType>;
};

export type FaultCode = {
  id: Scalars['ID'];
  code: Scalars['String'];
  details?: Maybe<FaultCodeDetails>;
  manufacturers: Array<Manufacturer>;
  errorType: FaultCodeErrorType;
  categories: Array<FaultCodeCategory>;
};


export type FaultCodeCodeArgs = {
  highlightMode?: Maybe<HighlightMode>;
};

export type FaultCodeCategory = {
  __typename?: 'FaultCodeCategory';
  name: Scalars['String'];
};

export type FaultCodeDetails = {
  __typename?: 'FaultCodeDetails';
  description: Scalars['String'];
  cause: Scalars['String'];
  solution: Scalars['String'];
};

export enum FaultCodeErrorType {
  Malfunction = 'MALFUNCTION',
  ServiceOrMaintenance = 'SERVICE_OR_MAINTENANCE',
  OperationMessage = 'OPERATION_MESSAGE'
}

export type FaultCodeSearchFilters = {
  manufacturerIds?: Maybe<Array<Scalars['ID']>>;
  systemIds?: Maybe<Array<Scalars['ID']>>;
  interfaceIds?: Maybe<Array<Scalars['ID']>>;
  errorType?: Maybe<FaultCodeErrorType>;
};

export enum FitInAvailability {
  None = 'NONE',
  Optional = 'OPTIONAL',
  Mandatory = 'MANDATORY'
}

export type Furnace = GenericSystem & ControlSystem & {
  __typename?: 'Furnace';
  id: Scalars['ID'];
  modelName: Scalars['String'];
  versionName: Scalars['String'];
  verboseNames: Array<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<GenericSystemImages>;
  manufacturers: Array<Manufacturer>;
  yellowFlags: Array<YellowFlag>;
  interfaceConfigurations: Array<InterfaceConfiguration>;
  tags: Array<Tag>;
  inventory?: Maybe<Inventory>;
  powerSupply?: Maybe<PowerSupplyType>;
  power: Scalars['String'];
  fuel: Scalars['String'];
  placement: FurnacePlacementType;
  heatingType?: Maybe<HeatingType>;
};


export type FurnaceModelNameArgs = {
  type?: Maybe<NameType>;
};

export enum FurnacePlacementType {
  External = 'EXTERNAL',
  Attachable = 'ATTACHABLE'
}

/** Interface for all types of systems, like Boiler, RoomThermostat, TranslationModule. */
export type GenericSystem = {
  id: Scalars['ID'];
  modelName: Scalars['String'];
  versionName?: Maybe<Scalars['String']>;
  verboseNames: Array<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<GenericSystemImages>;
  manufacturers: Array<Manufacturer>;
  yellowFlags: Array<YellowFlag>;
  interfaceConfigurations: Array<InterfaceConfiguration>;
  tags: Array<Tag>;
  inventory?: Maybe<Inventory>;
};


/** Interface for all types of systems, like Boiler, RoomThermostat, TranslationModule. */
export type GenericSystemModelNameArgs = {
  type?: Maybe<NameType>;
};

/** Images that show how the system looks like. */
export type GenericSystemImages = {
  __typename?: 'GenericSystemImages';
  /** The schematic image is a svg of the system. This image should we used when the system is shown to the customer */
  schematic?: Maybe<Image>;
  /** A photograph of the system */
  realLife?: Maybe<Image>;
  fitInSelection?: Maybe<Image>;
};

export enum HeatPumpType {
  WaterWaterHeatpump = 'WATER_WATER_HEATPUMP',
  AirWaterHeatpump = 'AIR_WATER_HEATPUMP',
  AirAirHeatpump = 'AIR_AIR_HEATPUMP',
  WaterAirHeatpump = 'WATER_AIR_HEATPUMP',
  Other = 'OTHER'
}

export enum HeatingType {
  CombiBoiler = 'COMBI_BOILER',
  HeatOnlySystemBoiler = 'HEAT_ONLY_SYSTEM_BOILER',
  DomesticHotWaterBoiler = 'DOMESTIC_HOT_WATER_BOILER',
  FourPipeSystemBoiler = 'FOUR_PIPE_SYSTEM_BOILER',
  Unknown = 'UNKNOWN'
}

/**
 * HighlightMode allows you to wrap matched results from a search within a specific set of characters.
 * Currently we only support MARKDOWN for highlighting. For example, if you search for "50", matched content in returned
 * fields will be hightlighted using the bold notation from Markdown, like so **E-50**. Please note that we currently only
 * hightlight the complete field that was matched. Example request:
 *
 *     query {
 *         searchFaultCodes(searchText: "50") {
 *             faultCodes {
 *                 code(highlightMode: MARKDOWN)
 *             }
 *         }
 *     }
 *
 * Results may contain code field like:
 *
 *     "code": "**E-50**"
 */
export enum HighlightMode {
  None = 'NONE',
  Markdown = 'MARKDOWN'
}

export type Image = {
  __typename?: 'Image';
  url: Scalars['String'];
};


export type ImageUrlArgs = {
  type?: Maybe<ImageType>;
};

export enum ImageType {
  Svg = 'SVG',
  Png = 'PNG'
}

export type Interface = {
  __typename?: 'Interface';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: InterfaceType;
  physicalType: PhysicalInterfaceType;
  priority: Scalars['Int'];
  compatibility: InterfaceCompatibility;
};

export enum InterfaceActivationType {
  None = 'NONE',
  Unknown = 'UNKNOWN',
  BcRelayBridgedRequired = 'BC_RELAY_BRIDGED_REQUIRED',
  BcRelayBridgedPossible = 'BC_RELAY_BRIDGED_POSSIBLE',
  Other = 'OTHER'
}

export enum InterfaceCompatibility {
  Compatible = 'COMPATIBLE',
  CompatibleWithYellowFlag = 'COMPATIBLE_WITH_YELLOW_FLAG',
  Incompatible = 'INCOMPATIBLE'
}

export type InterfaceConfiguration = {
  __typename?: 'InterfaceConfiguration';
  interface: Interface;
  connector?: Maybe<Connector>;
  comment?: Maybe<Scalars['String']>;
  physicalConnector?: Maybe<PhysicalConnector>;
  interfaceActivation?: Maybe<InterfaceActivationType>;
  enabled: Scalars['Boolean'];
  disabledReason?: Maybe<Scalars['String']>;
  applicableForTbmFlow?: Maybe<Scalars['Boolean']>;
};

export type InterfaceFaultCode = FaultCode & {
  __typename?: 'InterfaceFaultCode';
  id: Scalars['ID'];
  code: Scalars['String'];
  details?: Maybe<FaultCodeDetails>;
  manufacturers: Array<Manufacturer>;
  errorType: FaultCodeErrorType;
  categories: Array<FaultCodeCategory>;
  subCodes: Array<SubFaultCode>;
  interfaceReporters: Array<Interface>;
};


export type InterfaceFaultCodeCodeArgs = {
  highlightMode?: Maybe<HighlightMode>;
};

export enum InterfaceType {
  NotAnalyzed = 'NOT_ANALYZED',
  Wireless = 'WIRELESS',
  ResistorNetwork_2Wires = 'RESISTOR_NETWORK_2_WIRES',
  ResistorNetwork_3Wires = 'RESISTOR_NETWORK_3_WIRES',
  ResistorNetwork_4Wires = 'RESISTOR_NETWORK_4_WIRES',
  ResistorNetwork_5Wires = 'RESISTOR_NETWORK_5_WIRES',
  SingleRelay = 'SINGLE_RELAY',
  DualRelay = 'DUAL_RELAY',
  TripleRelay = 'TRIPLE_RELAY',
  QuadrupleRelay = 'QUADRUPLE_RELAY',
  Analog = 'ANALOG',
  Digital_2Wires = 'DIGITAL_2WIRES',
  Digital_3Wires = 'DIGITAL_3WIRES',
  Digital_4Wires = 'DIGITAL_4WIRES',
  UsStandardMultiRelay = 'US_STANDARD_MULTI_RELAY'
}

/** Inventory at the office */
export type Inventory = {
  __typename?: 'Inventory';
  boxId: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
};

/** Available languages for fault codes. Most fault codes have only english and german translations at the moment. */
export enum Language {
  En = 'EN',
  De = 'DE',
  Es = 'ES',
  It = 'IT',
  Fr = 'FR',
  Nl = 'NL'
}

/** Manufacturer */
export type Manufacturer = {
  __typename?: 'Manufacturer';
  id: Scalars['ID'];
  name: Scalars['String'];
  verboseNames: Array<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  logo?: Maybe<DisplayImages>;
};

export type ManufacturerSearchFilters = {
  hasFaultCodes?: Maybe<Scalars['Boolean']>;
};

export enum NameType {
  Full = 'FULL',
  Short = 'SHORT'
}

export type PaginationInfo = {
  __typename?: 'PaginationInfo';
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  total: Scalars['Int'];
};

export type PaginationInput = {
  /** Default offset 0 */
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};

export type PhysicalConnector = {
  __typename?: 'PhysicalConnector';
  name: Scalars['String'];
  picture: Image;
};

export enum PhysicalInterfaceType {
  NotAnalyzed = 'NOT_ANALYZED',
  Relay = 'RELAY',
  Digital = 'DIGITAL',
  Analog = 'ANALOG',
  ResistorNetwork = 'RESISTOR_NETWORK',
  Wireless = 'WIRELESS'
}

export enum PowerSupplyType {
  Ps_230V = 'PS_230V',
  Battery = 'BATTERY',
  LowVoltage = 'LOW_VOLTAGE',
  None = 'NONE'
}

export type ProductDevice = {
  __typename?: 'ProductDevice';
  id: Scalars['ID'];
  name: Scalars['String'];
  shortName?: Maybe<Scalars['String']>;
};

/** Query requests to retrieve information about HVAC systems (e.g. boilers, thermostate) and fault codes */
export type Query = {
  __typename?: 'Query';
  /**
   * Searches systems that match the provided query. If searchText and filters are not provided a list of all systems
   * is returned.
   *
   * The result is sorted by the ID of the system.
   */
  searchSystems: SearchSystemsResult;
  /** Gets information of a single system */
  system?: Maybe<GenericSystem>;
  /**
   * Searches fault codes that match the provided query. If searchText and filters are not provided a list of all
   * fault codes is returned.
   *
   * The result is sorted by the code of the fault code.
   */
  searchFaultCodes: SearchFaultCodesResult;
  /** Gets information of a single fault code */
  faultCode?: Maybe<FaultCode>;
  /**
   * Searches manufacturers that match the provided query. If searchText and filters are not provided a list of all
   * manufacturers is returned.
   *
   * The result is sorted by the name of the manufacturer.
   */
  searchManufacturers: SearchManufacturersResult;
  manufacturer?: Maybe<Manufacturer>;
};


/** Query requests to retrieve information about HVAC systems (e.g. boilers, thermostate) and fault codes */
export type QuerySearchSystemsArgs = {
  searchText?: Maybe<Scalars['String']>;
  filters?: Maybe<SystemSearchFilters>;
  pagination?: Maybe<PaginationInput>;
};


/** Query requests to retrieve information about HVAC systems (e.g. boilers, thermostate) and fault codes */
export type QuerySystemArgs = {
  id: Scalars['ID'];
};


/** Query requests to retrieve information about HVAC systems (e.g. boilers, thermostate) and fault codes */
export type QuerySearchFaultCodesArgs = {
  searchText?: Maybe<Scalars['String']>;
  filters?: Maybe<FaultCodeSearchFilters>;
  pagination?: Maybe<PaginationInput>;
};


/** Query requests to retrieve information about HVAC systems (e.g. boilers, thermostate) and fault codes */
export type QueryFaultCodeArgs = {
  id: Scalars['ID'];
  language?: Maybe<Language>;
};


/** Query requests to retrieve information about HVAC systems (e.g. boilers, thermostate) and fault codes */
export type QuerySearchManufacturersArgs = {
  searchText?: Maybe<Scalars['String']>;
  filters?: Maybe<ManufacturerSearchFilters>;
  pagination?: Maybe<PaginationInput>;
};


/** Query requests to retrieve information about HVAC systems (e.g. boilers, thermostate) and fault codes */
export type QueryManufacturerArgs = {
  id: Scalars['ID'];
};

export type RadiatorThermostat = GenericSystem & {
  __typename?: 'RadiatorThermostat';
  id: Scalars['ID'];
  modelName: Scalars['String'];
  versionName: Scalars['String'];
  verboseNames: Array<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<GenericSystemImages>;
  manufacturers: Array<Manufacturer>;
  yellowFlags: Array<YellowFlag>;
  interfaceConfigurations: Array<InterfaceConfiguration>;
  tags: Array<Tag>;
  inventory?: Maybe<Inventory>;
};


export type RadiatorThermostatModelNameArgs = {
  type?: Maybe<NameType>;
};

export type RadiatorValve = GenericSystem & {
  __typename?: 'RadiatorValve';
  id: Scalars['ID'];
  modelName: Scalars['String'];
  versionName: Scalars['String'];
  verboseNames: Array<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<GenericSystemImages>;
  manufacturers: Array<Manufacturer>;
  yellowFlags: Array<YellowFlag>;
  interfaceConfigurations: Array<InterfaceConfiguration>;
  tags: Array<Tag>;
  inventory?: Maybe<Inventory>;
};


export type RadiatorValveModelNameArgs = {
  type?: Maybe<NameType>;
};

export type RadiatorValveAdaptor = GenericSystem & {
  __typename?: 'RadiatorValveAdaptor';
  id: Scalars['ID'];
  modelName: Scalars['String'];
  versionName: Scalars['String'];
  verboseNames: Array<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<GenericSystemImages>;
  manufacturers: Array<Manufacturer>;
  yellowFlags: Array<YellowFlag>;
  interfaceConfigurations: Array<InterfaceConfiguration>;
  tags: Array<Tag>;
  inventory?: Maybe<Inventory>;
};


export type RadiatorValveAdaptorModelNameArgs = {
  type?: Maybe<NameType>;
};

export type Remote = ControlSystem & GenericSystem & {
  __typename?: 'Remote';
  id: Scalars['ID'];
  modelName: Scalars['String'];
  versionName: Scalars['String'];
  verboseNames: Array<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<GenericSystemImages>;
  manufacturers: Array<Manufacturer>;
  yellowFlags: Array<YellowFlag>;
  interfaceConfigurations: Array<InterfaceConfiguration>;
  tags: Array<Tag>;
  inventory?: Maybe<Inventory>;
  powerSupply?: Maybe<PowerSupplyType>;
};


export type RemoteModelNameArgs = {
  type?: Maybe<NameType>;
};

export type RfRemote = ControlSystem & GenericSystem & {
  __typename?: 'RfRemote';
  id: Scalars['ID'];
  modelName: Scalars['String'];
  versionName: Scalars['String'];
  verboseNames: Array<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<GenericSystemImages>;
  manufacturers: Array<Manufacturer>;
  yellowFlags: Array<YellowFlag>;
  interfaceConfigurations: Array<InterfaceConfiguration>;
  tags: Array<Tag>;
  inventory?: Maybe<Inventory>;
  powerSupply?: Maybe<PowerSupplyType>;
};


export type RfRemoteModelNameArgs = {
  type?: Maybe<NameType>;
};

export type RfRoomThermostat = ControlSystem & GenericSystem & {
  __typename?: 'RfRoomThermostat';
  id: Scalars['ID'];
  modelName: Scalars['String'];
  versionName: Scalars['String'];
  verboseNames: Array<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<GenericSystemImages>;
  manufacturers: Array<Manufacturer>;
  yellowFlags: Array<YellowFlag>;
  interfaceConfigurations: Array<InterfaceConfiguration>;
  tags: Array<Tag>;
  inventory?: Maybe<Inventory>;
  powerSupply?: Maybe<PowerSupplyType>;
};


export type RfRoomThermostatModelNameArgs = {
  type?: Maybe<NameType>;
};

export type RoomThermostat = ControlSystem & GenericSystem & {
  __typename?: 'RoomThermostat';
  id: Scalars['ID'];
  modelName: Scalars['String'];
  versionName: Scalars['String'];
  verboseNames: Array<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<GenericSystemImages>;
  manufacturers: Array<Manufacturer>;
  yellowFlags: Array<YellowFlag>;
  interfaceConfigurations: Array<InterfaceConfiguration>;
  tags: Array<Tag>;
  inventory?: Maybe<Inventory>;
  powerSupply?: Maybe<PowerSupplyType>;
};


export type RoomThermostatModelNameArgs = {
  type?: Maybe<NameType>;
};

export type RoomThermostatReceiver = ControlSystem & GenericSystem & {
  __typename?: 'RoomThermostatReceiver';
  id: Scalars['ID'];
  modelName: Scalars['String'];
  versionName: Scalars['String'];
  verboseNames: Array<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<GenericSystemImages>;
  manufacturers: Array<Manufacturer>;
  yellowFlags: Array<YellowFlag>;
  interfaceConfigurations: Array<InterfaceConfiguration>;
  tags: Array<Tag>;
  inventory?: Maybe<Inventory>;
  powerSupply?: Maybe<PowerSupplyType>;
};


export type RoomThermostatReceiverModelNameArgs = {
  type?: Maybe<NameType>;
};

export type SearchFaultCodesResult = {
  __typename?: 'SearchFaultCodesResult';
  faultCodes: Array<FaultCode>;
  paginationInfo: PaginationInfo;
};


export type SearchFaultCodesResultFaultCodesArgs = {
  language?: Maybe<Language>;
};

export type SearchManufacturersResult = {
  __typename?: 'SearchManufacturersResult';
  manufacturers: Array<Manufacturer>;
  paginationInfo: PaginationInfo;
};

export type SearchSystemsResult = {
  __typename?: 'SearchSystemsResult';
  systems: Array<GenericSystem>;
  paginationInfo: PaginationInfo;
};

export type SketchSystem = GenericSystem & {
  __typename?: 'SketchSystem';
  id: Scalars['ID'];
  modelName: Scalars['String'];
  versionName: Scalars['String'];
  verboseNames: Array<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<GenericSystemImages>;
  manufacturers: Array<Manufacturer>;
  yellowFlags: Array<YellowFlag>;
  interfaceConfigurations: Array<InterfaceConfiguration>;
  tags: Array<Tag>;
  inventory?: Maybe<Inventory>;
};


export type SketchSystemModelNameArgs = {
  type?: Maybe<NameType>;
};

export type SubFaultCode = FaultCode & {
  __typename?: 'SubFaultCode';
  id: Scalars['ID'];
  code: Scalars['String'];
  details?: Maybe<FaultCodeDetails>;
  manufacturers: Array<Manufacturer>;
  errorType: FaultCodeErrorType;
  categories: Array<FaultCodeCategory>;
};


export type SubFaultCodeCodeArgs = {
  highlightMode?: Maybe<HighlightMode>;
};

export enum SystemRole {
  Boiler = 'BOILER',
  ExternalReceiver = 'EXTERNAL_RECEIVER',
  ExternalControl = 'EXTERNAL_CONTROL',
  RoomThermostat = 'ROOM_THERMOSTAT',
  RfRoomThermostat = 'RF_ROOM_THERMOSTAT'
}

export type SystemSearchFilters = {
  systemIds?: Maybe<Array<Scalars['ID']>>;
  systemTypes?: Maybe<Array<SystemType>>;
  manufacturerIds?: Maybe<Array<Scalars['ID']>>;
  interfaceIds?: Maybe<Array<Scalars['ID']>>;
  yellowFlag?: Maybe<YellowFlagInput>;
  heatingTypes?: Maybe<Array<HeatingType>>;
  heatPumpTypes?: Maybe<Array<HeatPumpType>>;
  tags?: Maybe<Array<Scalars['String']>>;
  roles?: Maybe<Array<SystemRole>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInterfaceEnabled?: Maybe<Scalars['Boolean']>;
  hasInventory?: Maybe<Scalars['Boolean']>;
  boilerControlPlacement?: Maybe<BoilerControlPlacement>;
  furnacePlacement?: Maybe<FurnacePlacementType>;
  translationModulePlacement?: Maybe<TranslationModulePlacement>;
  fitInAvailability?: Maybe<Array<FitInAvailability>>;
  createdAt?: Maybe<DateRangeInput>;
  updatedAt?: Maybe<DateRangeInput>;
};

export enum SystemType {
  Boiler = 'Boiler',
  Furnace = 'Furnace',
  RadiatorThermostat = 'RadiatorThermostat',
  RadiatorValveAdaptor = 'RadiatorValveAdaptor',
  ControlBoard = 'ControlBoard',
  EffectiveBoilerControl = 'EffectiveBoilerControl',
  BoilerControl = 'BoilerControl',
  BoilerControlWithFitsIn = 'BoilerControlWithFitsIn',
  RoomThermostat = 'RoomThermostat',
  RfRoomThermostat = 'RfRoomThermostat',
  Remote = 'Remote',
  RfRemote = 'RfRemote',
  RoomThermostatReceiver = 'RoomThermostatReceiver',
  AuxiliaryControl = 'AuxiliaryControl',
  TranslationModule = 'TranslationModule',
  RadiatorValve = 'RadiatorValve',
  SketchSystem = 'SketchSystem'
}

export type SystemsFaultCode = FaultCode & {
  __typename?: 'SystemsFaultCode';
  id: Scalars['ID'];
  code: Scalars['String'];
  details?: Maybe<FaultCodeDetails>;
  manufacturers: Array<Manufacturer>;
  errorType: FaultCodeErrorType;
  categories: Array<FaultCodeCategory>;
  subCodes: Array<SubFaultCode>;
  systemReporters: Array<GenericSystem>;
};


export type SystemsFaultCodeCodeArgs = {
  highlightMode?: Maybe<HighlightMode>;
};

/** Tags */
export type Tag = {
  __typename?: 'Tag';
  name: Scalars['String'];
  category?: Maybe<TagCategory>;
};

export type TagCategory = {
  __typename?: 'TagCategory';
  name: Scalars['String'];
};

export type TranslationModule = ControlSystem & GenericSystem & {
  __typename?: 'TranslationModule';
  id: Scalars['ID'];
  modelName: Scalars['String'];
  versionName: Scalars['String'];
  verboseNames: Array<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<GenericSystemImages>;
  manufacturers: Array<Manufacturer>;
  yellowFlags: Array<YellowFlag>;
  interfaceConfigurations: Array<InterfaceConfiguration>;
  tags: Array<Tag>;
  inventory?: Maybe<Inventory>;
  powerSupply?: Maybe<PowerSupplyType>;
  placement?: Maybe<TranslationModulePlacement>;
};


export type TranslationModuleModelNameArgs = {
  type?: Maybe<NameType>;
};

export enum TranslationModulePlacement {
  External = 'EXTERNAL',
  Internal = 'INTERNAL'
}

export type YellowFlag = {
  __typename?: 'YellowFlag';
  type: YellowFlagType;
  title: Scalars['String'];
  enabled: Scalars['Boolean'];
  explanation?: Maybe<Scalars['String']>;
  devices: Array<ProductDevice>;
};

export type YellowFlagInput = {
  types?: Maybe<Array<YellowFlagType>>;
  deviceIds?: Maybe<Array<Scalars['ID']>>;
};

/** This is the marker that affects Compatibility or Installability computation result */
export enum YellowFlagType {
  Compatibility = 'Compatibility',
  Installation = 'Installation'
}
