import { gql, Apollo, QueryRef } from "apollo-angular";
import { ApolloQueryResult } from "@apollo/client/core";
import { Injectable } from "@angular/core";

import { Query, SystemRole } from "../generated/ivar/types";

import { Observable } from "rxjs";

const systemByIdQuery = gql`
  query ($systemId: ID!) {
    system(id: $systemId) {
      id
      modelName
      manufacturers {
        name
      }
      thumbnail {
        schematic {
          url(type: SVG)
        }
      }
    }
  }
`;

const searchSystemQuery = gql`
  query ($systemIds: [ID!]) {
    searchSystems(filters: { systemIds: $systemIds }) {
      systems {
        id
        modelName
        manufacturers {
          name
        }
        thumbnail {
          schematic {
            url(type: SVG)
          }
        }
      }
    }
  }
`;

const searchSystemsByRoleQuery = gql`
  query ($searchText: String, $roles: [SystemRole!]) {
    searchSystems(searchText: $searchText, filters: { roles: $roles }) {
      systems {
        id
        modelName
        manufacturers {
          name
        }
        thumbnail {
          schematic {
            url(type: SVG)
          }
        }
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class SearchSystemsService {
  constructor(private apollo: Apollo) {}

  systemByIdQuery(
    systemId: number
  ): Observable<ApolloQueryResult<Query>> | null {
    return this.apollo.watchQuery<Query>({
      query: systemByIdQuery,
      variables: { systemId: systemId },
    }).valueChanges;
  }

  searchSystemsQuery(
    systemIds: number[]
  ): Observable<ApolloQueryResult<Query>> | null {
    if (systemIds.length == 0) {
      return null;
    }
    return this.apollo.watchQuery<Query>({
      query: searchSystemQuery,
      variables: { systemIds: systemIds },
    }).valueChanges;
  }

  buildSearchSystemsByRoleQuery(
    roles: SystemRole[],
    searchText?: string
  ): QueryRef<Query> | null {
    if (roles.length == 0) {
      return null;
    }
    return this.apollo.watchQuery<Query>({
      query: searchSystemsByRoleQuery,
      variables: { roles: roles, searchText: searchText },
    });
  }
}
